import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { IMasterValue } from "shared/utils/template/excelTemplate";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { TemplateExcelDataInfo, User } from "services/models";

interface IAccountInputField {
  setting: TemplateExcelDataInfo;
  value: IMasterValue;
  onChange: (value: IMasterValue) => void;
  userList: Array<User>;
}

const AccountInputField = ({
  setting,
  value,
  onChange,
  userList,
}: IAccountInputField) => {
  const [list, setList] = useState<Array<any>>([]);
  const otherTitle = `その他（${setting.name}）`;

  useEffect(() => {
    if (userList) setDataList();
  }, [userList]);

  const setDataList = () => {
    if (userList) {
      const data_list = addWhiteSpace(addManualInput([...userList]));
      setList(data_list);
    }
  };

  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const addManualInput = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(data.length, 0, {
      PK: "0",
      SK: "0",
      full_name: "その他(手入力)",
      is_manual_input: true,
    });
    return data;
  };

  return (
    <>
      <SelectLabel
        label={
          setting.required ? (
            <LabelRequired title={setting.name} />
          ) : (
            setting.name
          )
        }
        value={value?.id ?? ""}
        onChange={(e) => {
          if (e.target.value != "") {
            const selectionItem = list.find(
              (item) => item?.SK === e.target.value,
            );
            onChange({
              id: selectionItem.SK,
              name: selectionItem.is_manual_input
                ? ""
                : selectionItem.full_name,
              is_manual_input: selectionItem.is_manual_input,
            });
          } else {
            onChange({
              id: undefined,
              name: "",
              is_manual_input: false,
            });
          }
        }}
      >
        {list.map((item, index) => {
          if (!item) {
            return (
              <MenuItem value={""} key={index}>
                <em>未選択</em>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem value={item?.SK} key={index}>
                {item?.full_name}
              </MenuItem>
            );
          }
        })}
      </SelectLabel>
      <TextField
        label={otherTitle}
        value={value?.is_manual_input ? value.name : ""}
        inputProps={{
          maxLength: 30,
        }}
        onChange={(e) => {
          onChange({ ...value, name: e.target.value });
        }}
        disabled={!value?.is_manual_input}
      />
    </>
  );
};

export default AccountInputField;
