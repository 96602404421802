import { getSystemByPK } from "@api/system";
import { createExcelTemplate, getSignedUrlFile } from "@api/template";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { EXCEL_TEMPLATE_INPUT, SYSTEM_KEY } from "@shared-constants";
import { createFormUpload, fileDownload, getFileByUrl } from "@utils/index";
import { Validation } from "@validation";
import Dropzone from "components/atoms/Dropzone";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CreateTemplate from "screens/CreateTemplate";
import { CreateExcelTemplate, TemplateExcel } from "services/models";

interface IProps {
  template?: TemplateExcel;
  isNew?: boolean;
  editable?: boolean;
  onChange?: Function;
  onError?: Function;
  activityBaseId: string;
}

const initial_data: TemplateExcel = {
  PK: "",
  SK: "",
  excel_path: "",
  excel_name: "",
  start_col: "A",
  start_row: "1",
  end_col: "A",
  end_row: "1",
  sheet_name: "",
  font: "sans-serif",
  paper_size: "",
  horizontal_pixel_size: "",
  vertical_pixel_size: "",
  is_landscape: false,
  data_info: [],
  excel: undefined,
};
const TemplateCreateBox = (
  {
    template,
    isNew = false,
    editable = true,
    onChange = () => {},
    onError = () => {},
    activityBaseId,
  }: IProps,
  ref: any,
) => {
  const [excelForm, setExcelForm] = useState<TemplateExcel>(initial_data);
  const [originData, setOriginData] = useState<TemplateExcel>(initial_data);
  const [formError, setFormError] = useState({
    excel: "",
    start_row: "",
    end_row: "",
    sheet_name: "",
  });
  const [openDropzone, setOpenDropzone] = useState(false);
  const [openTemplateCreate, setOpenTemplateCreate] = useState(false);
  const [htmlData, setHtmlData] = useState<Array<CreateExcelTemplate>>([]);
  const [tableRowsHeight, setTableRowsHeight] = useState<Array<string>>([]);
  const [htmlString, setHtmlString] = useState<string>("");
  const [listPaperSize, setListPaperSize] = useState<Array<any>>([]);
  const [listInputName, setListInputName] = useState<Array<string>>([]);
  const [clearStateForm, setClearStateForm] = useState<boolean>(false);
  const excelInfoRef = useRef<any>();
  const createTemplateRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    stateForm: {
      ...excelForm,
      data_info: createTemplateRef.current.stateForm,
    },
    formError: formError,
    validationAllCheck: validationAllCheck,
    hasUpdateData: hasUpdateData,
  }));

  useEffect(() => {
    getSystemByPK(SYSTEM_KEY.PAPER_SIZE).then((res) => {
      if (res?.data) {
        setListPaperSize(res.data);
        if (res.data.length > 0 && excelForm.paper_size == "") {
          setExcelForm((prev) => ({
            ...prev,
            paper_size: res.data[0].paper_size,
            horizontal_pixel_size: res.data[0].horizontal_pixel_size,
            vertical_pixel_size: res.data[0].vertical_pixel_size,
          }));
          setOriginData((prev) => ({
            ...prev,
            paper_size: res.data[0].paper_size,
            horizontal_pixel_size: res.data[0].horizontal_pixel_size,
            vertical_pixel_size: res.data[0].vertical_pixel_size,
          }));
        }
      }
    });
  }, []);

  const setExcelTemplateData = async (template?: TemplateExcel) => {
    if (template) {
      // 画像設定の署名付きURLを取得
      const list_draw_image = template.data_info.filter(
        (item) => item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
      );
      for (const [index, item] of list_draw_image.entries()) {
        if (item.image?.path_file) {
          const newUri = await getSignedUrlFile(item.image.path_file).then(
            (res) => {
              if (res?.link_url) {
                return res.link_url;
              }
            },
          );
          item.image.uri = newUri;
          item.image.non_Local = true;
        }
      }
      setExcelForm(template);
      setOriginData(template);
      if (template.data_info && template.data_info.length) {
        // 登録済みの項目名を取得
        const list_input_name = template.data_info
          .filter((item) => item.input !== EXCEL_TEMPLATE_INPUT.IMAGE_PAGE)
          .map((item) => item.name);
        setListInputName(list_input_name);
      }
      if (template.excel.non_Local) {
        excelInfoRef.current = template;
      }
    }
  };

  useEffect(() => {
    setExcelTemplateData(template);
  }, [template]);

  useEffect(() => {
    onChange(excelForm);
    onError(!validationAllCheck(false));
  }, [excelForm]);

  // ------------------------------------------------------------------
  // エクセル取り込み
  // ------------------------------------------------------------------
  const handleImportExcel = async () => {
    const excel_info = { ...excelForm };
    if (
      excelInfoRef.current &&
      _.isEqual(excelInfoRef.current, excel_info) &&
      htmlString != ""
    ) {
      // Excelの変更がない場合、HTML取得せずにテンプレート設定を開く
      setClearStateForm(false);
      setOpenTemplateCreate(true);
      return;
    }

    if (!validationAllCheck()) return;

    try {
      LoadingOverlayController.show();
      const data = {
        range: `${excelForm.start_col}${excelForm.start_row}:${excelForm.end_col}${excelForm.end_row}`,
        sheet_name: excelForm.sheet_name,
        font_name: excelForm.font,
        is_landscape: excelForm.is_landscape,
        horizontal: excelForm.horizontal_pixel_size,
        vertical: excelForm.vertical_pixel_size,
      };
      let excel = excelForm.excel;
      if (excel.non_Local) {
        // 複製、編集時はファイルを取得
        excel = await getFileByUrl(
          excel.uri,
          excel.path,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        );
      }
      let formData = createFormUpload(excel, data);
      const res = await createExcelTemplate(formData);
      if (res) {
        setClearStateForm(
          excelInfoRef.current && _.isEqual(excelInfoRef.current, excel_info)
            ? false
            : true,
        );
        setHtmlData(JSON.parse(JSON.stringify(res.response)));
        setTableRowsHeight(JSON.parse(JSON.stringify(res.rows_height)));
        setHtmlString(res.html);
        setOpenTemplateCreate(true);
        excelInfoRef.current = excel_info;
      }
    } catch (e: any) {
      console.log(e);
      ModalController.show({ message: e?.detail, visibleButton2: true });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ------------------------------------------------------------------
  // 画面描画
  // ------------------------------------------------------------------
  const getIndexChar = useCallback((n: number) => {
    var codeAtA = "a".charCodeAt(0);
    var codeAtZ = "z".charCodeAt(0);
    var len = codeAtZ - codeAtA + 1;

    var s = "";
    while (n >= 0) {
      s = String.fromCharCode((n % len) + codeAtA).toUpperCase() + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }, []);

  // ------------------------------------------------------------------
  // 入力項目
  // ------------------------------------------------------------------
  const validationAllCheck = (update: boolean = true) => {
    let mess_excel = validate("excel", undefined, update);
    let mess_row_s = validate("start_row", undefined, update);
    let mess_row_e = validate("end_row", undefined, update);
    let mess_sheet_name = validate("sheet_name", undefined, update);
    const form_error = {
      excel: mess_excel,
      start_row: mess_row_s,
      end_row: mess_row_e,
      sheet_name: mess_sheet_name,
    };
    if (update) setFormError(form_error);

    return !Object.values(form_error).some((value) => value != "");
  };

  const validate = (
    field: keyof typeof excelForm | "excel",
    value?: any,
    update: boolean = true,
  ) => {
    let message = "";
    switch (field) {
      case "excel":
        const validate_value = value !== undefined ? value : excelForm.excel;
        message = Validation.validate({
          type: "text",
          value: validate_value ? "true" : "",
          name: "Excelファイル",
          required: true,
        });
        break;
      case "start_row":
        message = Validation.validate({
          type: "number",
          value: excelForm.start_row,
          name: "取込み範囲（開始）",
          required: true,
          min_value: 1,
        });
        break;
      case "end_row":
        message = Validation.validate({
          type: "number",
          value: excelForm.end_row,
          name: "取込み範囲（終了）",
          required: true,
          min_value: 1,
        });
        break;
      case "sheet_name":
        message = Validation.validate({
          type: "text",
          value: excelForm.sheet_name,
          name: "取込み対象シート名",
          required: true,
        });
        break;
    }
    if (update) setFormError({ ...formError, [field]: message });
    return message;
  };

  const hasUpdateData = () => {
    return (
      (excelForm && originData && !_.isEqual(excelForm, originData)) ||
      (createTemplateRef.current &&
        !_.isEqual(
          createTemplateRef.current.stateForm,
          template?.data_info ?? [],
        ))
    );
  };

  // ------------------------------------------------------------------
  // ダウンロード
  // ------------------------------------------------------------------
  const handleDownload = async (file_name: string, uri: string) => {
    await fileDownload(file_name, uri);
  };

  return (
    <>
      <DropzoneDialog
        open={openDropzone}
        onClose={() => setOpenDropzone(false)}
        onChoose={(res) => {
          setOpenDropzone(false);
          setExcelForm({ ...excelForm, excel: res[0] });
        }}
      />
      <CreateTemplate
        ref={createTemplateRef}
        open={openTemplateCreate}
        setOpen={setOpenTemplateCreate}
        data={template?.data_info ?? []}
        htmlData={htmlData}
        tableRowsHeight={tableRowsHeight}
        htmlString={htmlString}
        onSave={() => {
          onChange(excelForm);
        }}
        excelForm={excelForm}
        listInputName={listInputName}
        clearStateForm={clearStateForm}
        activityBaseId={activityBaseId}
      />
      <Stack width={"100%"}>
        <FormControl>
          <InputLabel error={formError.excel.length > 0}>
            <LabelRequired title="Excelファイル" />
          </InputLabel>
          {!excelForm.excel ? (
            <Dropzone
              onChoose={(res) => {
                setExcelForm((prev) => ({ ...prev, excel: res[0] }));
                validate("excel", res[0]);
              }}
              maxFiles={1}
              showFileList={false}
              accept="excel"
            />
          ) : (
            <Typography color={"GrayText"}>
              {excelForm.excel.path}
              <IconButton
                onClick={() => {
                  setExcelForm((prev) => ({ ...prev, excel: undefined }));
                  validate("excel", null);
                }}
              >
                <Cancel />
              </IconButton>
            </Typography>
          )}
          <FormHelperText error={formError.excel.length > 0}>
            {formError.excel}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Stack direction={{ xs: "column", md: "row" }} mt={1}>
            <Stack direction={"row"}>
              <SelectLabel
                label={<LabelRequired title="取込み範囲（開始）" />}
                value={excelForm.start_col}
                onChange={(e) =>
                  setExcelForm({ ...excelForm, start_col: e.target.value })
                }
                error={formError.start_row.length > 0}
              >
                {[...Array(78)].map((_, i) => (
                  <MenuItem key={i} value={getIndexChar(i)}>
                    {getIndexChar(i)}
                  </MenuItem>
                ))}
              </SelectLabel>
              <TextField
                sx={{ width: "100px", pt: "26px" }}
                InputProps={{}}
                value={excelForm.start_row}
                onChange={(e) =>
                  setExcelForm({ ...excelForm, start_row: e.target.value })
                }
                onBlur={() => validate("start_row")}
                error={formError.start_row.length > 0}
              />
            </Stack>
            <Typography pt={5} sx={{ display: { xs: "none", md: "inherit" } }}>
              :
            </Typography>
            <Stack direction={"row"}>
              <SelectLabel
                label={<LabelRequired title="取込み範囲（終了）" />}
                value={excelForm.end_col}
                onChange={(e) =>
                  setExcelForm({ ...excelForm, end_col: e.target.value })
                }
                error={formError.end_row.length > 0}
              >
                {[...Array(78)].map((_, i) => (
                  <MenuItem key={i} value={getIndexChar(i)}>
                    {getIndexChar(i)}
                  </MenuItem>
                ))}
              </SelectLabel>
              <TextField
                sx={{ width: "100px", pt: "26px" }}
                InputProps={{}}
                value={excelForm.end_row}
                onChange={(e) =>
                  setExcelForm({ ...excelForm, end_row: e.target.value })
                }
                onBlur={() => validate("end_row")}
                error={formError.end_row.length > 0}
              />
            </Stack>
          </Stack>
          <FormHelperText error={formError.start_row.length > 0}>
            {formError.start_row}
          </FormHelperText>
          <FormHelperText error={formError.end_row.length > 0}>
            {formError.end_row}
          </FormHelperText>
        </FormControl>
        <TextField
          label={<LabelRequired title="取込対象シート名" />}
          value={excelForm.sheet_name}
          onChange={(e) =>
            setExcelForm({ ...excelForm, sheet_name: e.target.value })
          }
          onBlur={() => validate("sheet_name")}
          error={formError.sheet_name.length > 0}
          helperText={formError.sheet_name}
        />
        <SelectLabel
          label={<LabelRequired title="フォント" />}
          value={excelForm.font}
          onChange={(e) => setExcelForm({ ...excelForm, font: e.target.value })}
        >
          <MenuItem value={"sans-serif"}>ゴシック体</MenuItem>
          <MenuItem value={"serif"}>明朝体</MenuItem>
        </SelectLabel>
        <SelectLabel
          label={<LabelRequired title="用紙サイズ" />}
          value={listPaperSize?.length > 0 ? excelForm.paper_size : ""}
          onChange={(e) => {
            const selected = listPaperSize?.find(
              (item) => item.paper_size == e.target.value,
            );
            if (selected) {
              setExcelForm({
                ...excelForm,
                paper_size: e.target.value,
                horizontal_pixel_size: selected.horizontal_pixel_size,
                vertical_pixel_size: selected.vertical_pixel_size,
              });
            }
          }}
        >
          {listPaperSize?.map((item, index) => (
            <MenuItem value={item.paper_size} key={index}>
              {item.paper_size}
            </MenuItem>
          ))}
        </SelectLabel>
        <SelectLabel
          label={<LabelRequired title="用紙向き" />}
          value={excelForm.is_landscape ? "1" : "0"}
          onChange={(e) =>
            setExcelForm({
              ...excelForm,
              is_landscape: e.target.value == "0" ? false : true,
            })
          }
        >
          <MenuItem value={0}>縦</MenuItem>
          <MenuItem value={1}>横</MenuItem>
        </SelectLabel>
        {!isNew && (
          <FormControl>
            <InputLabel>Excelファイルダウンロード</InputLabel>
            <Box>
              <Link
                component="button"
                onClick={async () =>
                  await handleDownload(
                    template?.excel?.path,
                    template?.excel?.uri,
                  )
                }
                variant="body1"
              >
                {template?.excel?.path}
              </Link>
            </Box>
          </FormControl>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          <Button onClick={handleImportExcel}>テンプレート設定</Button>
        </Box>
      </Stack>
    </>
  );
};
export default forwardRef(TemplateCreateBox);
