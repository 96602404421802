import React from "react";
import { FormControl, InputLabel } from "@mui/material";
import StepperInput, { IStepperInput } from "../StepperInput";
import LabelRequired from "../LabelRequired";

type IStepperInputLabel = Omit<IStepperInput, "label"> & { label: string };

const StepperInputLabel: React.FC<IStepperInputLabel> = ({
  label,
  required,
  ...props
}) => {
  return (
    <FormControl>
      <InputLabel>
        {required ? <LabelRequired title={label} /> : label}
      </InputLabel>
      <StepperInput required {...props} />
    </FormControl>
  );
};

export default StepperInputLabel;
