import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import {
  EXCEL_DATE_FORMAT,
  EXCEL_ROUND,
  EXCEL_ROUND_PLACE,
  EXCEL_TEMPLATE_FONT_COLOR,
  EXCEL_TEMPLATE_INPUT,
} from "@shared-constants";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { TemplateExcelDataInfo } from "services/models";
import { Validation } from "@validation";
import _ from "lodash";
import messages from "config/messages";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getMasterType } from "@api/masterType";
import Dropzone from "components/atoms/Dropzone";
import { createObjectURL } from "@utils/index";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";

interface IProps {
  data?: TemplateExcelDataInfo;
  excelTemplate?: Array<TemplateExcelDataInfo>;
  isLandscape?: boolean;
  listInputName?: Array<string>;
  activityBaseId: string;
}

interface IListSelectedMaster {
  coordinate: number; // 1000から連番
  custom_item_name: string; // 紐づけ元項目名 + " - " + マスタ追加項目の項目名,
  master_coordinate: string; // 紐づけ元マスタの座標
  master_attr: string; // マスタ追加項目のattribute
}

const initial_form_error = {
  name: "",
  maxlen: "",
  lineRows: "",
  fontSize: "",
  image: "",
  calc: "",
};

const ListImagePageSelectable: Array<number> = [
  EXCEL_TEMPLATE_INPUT.TEXT,
  EXCEL_TEMPLATE_INPUT.MASTER,
  EXCEL_TEMPLATE_INPUT.DATE,
  EXCEL_TEMPLATE_INPUT.REPORT_NO,
  EXCEL_TEMPLATE_INPUT.USER_NAME,
];

const ListSealSelectable: Array<number> = [EXCEL_TEMPLATE_INPUT.USER_NAME];

const CreateTemplateInput = (
  {
    data,
    excelTemplate = [],
    isLandscape,
    listInputName = [],
    activityBaseId,
  }: IProps,
  ref: any,
) => {
  const [stateForm, setStateForm] = useState<TemplateExcelDataInfo>();
  const [originData, setOriginData] = useState<TemplateExcelDataInfo>();
  const [formError, setFormError] = useState(initial_form_error);
  const [listMaster, setListMaster] = useState<any[]>([]);

  useEffect(() => {
    setStateForm(data);
    setOriginData(data);
    setFormError(initial_form_error);
  }, [data]);

  useEffect(() => {
    const getListMaster = async () => {
      LoadingOverlayController.show();
      await getMasterType(activityBaseId)
        .then((res) => {
          if (res?.data) {
            setListMaster(res.data);
          }
        })
        .finally(() => LoadingOverlayController.hide());
    };
    getListMaster();
  }, [activityBaseId]);

  useImperativeHandle(ref, () => ({
    stateForm: stateForm,
    hasUpdateData: hasUpdateData,
    validationAllCheck: validationAllCheck,
  }));

  const hasUpdateData = () => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  };

  const validationAllCheck = () => {
    let mess_name = validate("name");
    let mess_maxlen =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.TEXT ? validate("maxlen") : "";
    let mess_line_rows =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.TEXT ? validate("lineRows") : "";
    let mess_font_size = validate("fontSize");
    let mess_draw_image =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE
        ? validate("image")
        : "";
    let mess_calc =
      stateForm?.input == EXCEL_TEMPLATE_INPUT.CALC ? validate("calc") : "";

    const form_error = {
      name: mess_name,
      maxlen: mess_maxlen,
      lineRows: mess_line_rows,
      fontSize: mess_font_size,
      image: mess_draw_image,
      calc: mess_calc,
    };
    setFormError(form_error);

    return !Object.values(form_error).some((value) => value != "");
  };

  const validate = (field: string) => {
    if (!stateForm) return "";

    let message = "";
    switch (field) {
      case "name":
        message = Validation.validate({
          type: "text",
          value: stateForm[field],
          name: "項目名",
          required: true,
        });
        if (message === "") {
          const exist = excelTemplate.some(
            (item) =>
              item.coordinate != stateForm.coordinate &&
              item.name == stateForm.name,
          );
          if (exist) {
            message = messages.COMMON.ERROR.MSG_EXISTING("項目名");
          }
        }
        break;
      case "maxlen":
        message = Validation.validate({
          type: "number",
          name: "上限桁数",
          value: stateForm[field],
          required: false,
          min_value: 1,
        });
        break;
      case "lineRows":
        message = Validation.validate({
          type: "number",
          name: "行数",
          value: stateForm[field],
          required: true,
          min_value: 1,
        });
        break;
      case "fontSize":
        message = Validation.validate({
          type: "number",
          name: "フォントサイズ",
          value: stateForm[field],
          required: true,
          min_value: 1,
        });
        break;
      case "image":
        message = Validation.validate({
          type: "text",
          name: "画像",
          value: stateForm[field]?.uri,
          required: true,
        });
        break;
      case "calc":
        message = Validation.validate({
          type: "text",
          name: "計算式",
          value: stateForm[field],
          required: true,
        });
        break;
    }
    setFormError({ ...formError, [field]: message });
    return message;
  };

  const _renderInput = useCallback(() => {
    if (!stateForm) return <></>;
    switch (stateForm.input) {
      case EXCEL_TEMPLATE_INPUT.TEXT:
        return (
          <>
            {NameInput}
            {SelectInputType}
            <TextField
              label="上限桁数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() => validate("maxlen")}
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            <Box>
              <CheckboxLabel
                label="改行あり"
                checked={stateForm.multiline}
                onChange={(_, checked) => {
                  setStateForm({
                    ...stateForm,
                    multiline: checked,
                    lineRows: checked ? stateForm.lineRows : 1,
                  });
                }}
              />
            </Box>
            <TextField
              label={<LabelRequired title="行数" />}
              value={stateForm.lineRows}
              onChange={(e) => {
                let v: any = e.target.value;
                if (v && !isNaN(Number(v))) {
                  v = Number(v);
                }
                setStateForm({ ...stateForm, lineRows: v });
              }}
              disabled={
                stateForm.input != EXCEL_TEMPLATE_INPUT.TEXT ||
                !stateForm.multiline
              }
              onBlur={() => validate("lineRows")}
              error={formError?.lineRows.length > 0}
              helperText={formError?.lineRows}
            />
            {DefaultInput}
            {RequiredInput}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.MASTER:
        return (
          <>
            {NameInput}
            {SelectInputType}
            <SelectLabel
              label={<LabelRequired title="マスタ" />}
              value={stateForm.master}
              onChange={(e) => {
                setStateForm({ ...stateForm, master: e.target.value });
              }}
            >
              {listMaster.map((item) => (
                <MenuItem value={item.prefix} key={item.SK}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectLabel>
            {RequiredInput}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.DATE:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            <SelectLabel
              label={<LabelRequired title="表示形式" />}
              value={stateForm.dateFormat ?? EXCEL_DATE_FORMAT[0].value}
              onChange={(e) => {
                setStateForm({ ...stateForm, dateFormat: e.target.value });
              }}
            >
              {EXCEL_DATE_FORMAT.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            {RequiredInput}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.IMAGE:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {RequiredInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.IMAGE_PAGE:
        return (
          <>
            <TextField
              label="画像タイトル"
              value={stateForm.titleImage}
              onChange={(e) => {
                setStateForm({ ...stateForm, titleImage: e.target.value });
              }}
            />
            {ImagePageHeader("項目1", "headImage1")}
            {ImagePageHeader("項目2", "headImage2")}
            {ImagePageHeader("項目3", "headImage3")}
            {ImagePageHeader("項目4", "headImage4")}
            {isLandscape && (
              <>
                {ImagePageHeader("項目5", "headImage5")}
                {ImagePageHeader("項目6", "headImage6")}
              </>
            )}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.REPORT_NO:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.USER_NAME:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
            {RequiredInput}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.USER_SEAL:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {DefaultInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.APPROVED_SEAL:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {ApprovalStep}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.APPROVED_DATE:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {ApprovalStep}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.SIGNATURE:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {RequiredInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.DRAW_IMAGE:
        return (
          <>
            {NameInput}
            {SelectInputType}
            <FormControl>
              <CheckboxLabel
                label="お絵描きする"
                checked={stateForm.isDraw}
                onChange={(_, checked) => {
                  setStateForm({ ...stateForm, isDraw: checked });
                }}
              />
            </FormControl>
            {stateForm.image && stateForm.image.uri !== "" ? (
              <CancelBtnBadge
                onClick={() => {
                  setStateForm({ ...stateForm, image: undefined });
                }}
                sx={{ width: "100%" }}
                componentsProps={{ badge: { style: { right: "7%" } } }}
              >
                <Box
                  component="img"
                  src={stateForm.image?.uri}
                  sx={{
                    height: 140,
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: 1,
                  }}
                />
              </CancelBtnBadge>
            ) : (
              <Box width={"100%"}>
                <Dropzone
                  onChoose={(res) => {
                    const uri = createObjectURL(res[0]);
                    res[0]["uri"] = uri;
                    setFormError({ ...formError, image: "" });
                    setStateForm({ ...stateForm, image: res[0] });
                  }}
                  maxFiles={1}
                  showFileList={false}
                />
              </Box>
            )}
            <FormHelperText error>{formError?.image}</FormHelperText>
          </>
        );
      case EXCEL_TEMPLATE_INPUT.CALC:
        return (
          <>
            {NameInput}
            {SelectInputType}
            <TextField
              label={<LabelRequired title="計算式" />}
              value={stateForm.calc}
              onChange={(e) => {
                setStateForm({ ...stateForm, calc: e.target.value });
              }}
              onBlur={() => validate("calc")}
              error={formError?.calc.length > 0}
              helperText={formError?.calc}
            />
            <SelectLabel
              label={<LabelRequired title="小数点以下" />}
              value={stateForm.calcRoundPlace ?? EXCEL_ROUND_PLACE[0].value}
              onChange={(e) => {
                setStateForm({
                  ...stateForm,
                  calcRoundPlace: Number(e.target.value),
                });
              }}
            >
              {EXCEL_ROUND_PLACE.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            <SelectLabel
              label={<LabelRequired title="端数処理" />}
              value={stateForm.calcRound ?? EXCEL_ROUND[0].value}
              onChange={(e) => {
                setStateForm({ ...stateForm, calcRound: e.target.value });
              }}
            >
              {EXCEL_ROUND.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </SelectLabel>
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      case EXCEL_TEMPLATE_INPUT.NUMBER:
        return (
          <>
            {NameInput}
            {SelectInputType}
            <TextField
              label="上限桁数"
              value={stateForm.maxlen}
              onChange={(e) => {
                setStateForm({ ...stateForm, maxlen: e.target.value });
              }}
              onBlur={() => validate("maxlen")}
              error={formError?.maxlen.length > 0}
              helperText={formError?.maxlen}
            />
            {DefaultInput}
            {RequiredInput}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
      default:
        return (
          <>
            {NameInput}
            {SelectInputType}
            {FontSizeInput}
            {FontColorInput}
          </>
        );
    }
  }, [stateForm, formError]);

  const nameInputOptions = useMemo(() => {
    // 登録済みの項目名から、未設定の項目名を抽出
    const list_name = excelTemplate.map((value) => value.name);
    return listInputName.filter((value) => !list_name.includes(value));
  }, [listInputName, excelTemplate]);

  // 入力設定されたマスタに紐づく追加項目のリスト
  const listSelectedMaster: IListSelectedMaster[] = useMemo(() => {
    let response: any[] = [];
    excelTemplate.forEach((value) => {
      if (
        value.input == EXCEL_TEMPLATE_INPUT.MASTER &&
        value.coordinate != stateForm?.coordinate
      ) {
        // 入力方法がマスタ、かつ、表示中の入力設定以外のとき
        const selected = listMaster.find((v) => v.prefix === value.master);
        if (selected && selected.is_usermade) {
          // ユーザー作成マスタのとき
          const custom_item: any[] = selected.custom_item || [];
          const selected_custom_master = custom_item.map((v, i) => ({
            coordinate:
              EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM + response.length + i, // 1000から連番
            custom_item_name: value.name + " - " + v.name,
            master_coordinate: value.coordinate,
            master_attr: v.barcode ? "barcode_item" : "item" + (i + 1),
          }));
          response = response.concat(selected_custom_master);
        }
      }
    });
    return response;
  }, [stateForm, excelTemplate]);

  const NameInput = useMemo((): React.ReactNode => {
    return (
      stateForm && (
        <Autocomplete
          freeSolo
          disableClearable
          options={nameInputOptions}
          value={stateForm.name ?? null}
          onInputChange={(e, newValue) => {
            setStateForm({ ...stateForm, name: newValue });
          }}
          onBlur={() => validate("name")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<LabelRequired title="項目名" />}
              error={formError?.name.length > 0}
              helperText={formError?.name}
            />
          )}
        />
      )
    );
  }, [stateForm, formError, nameInputOptions]);

  const SelectInputType = useMemo((): React.ReactNode => {
    const getDefaultValue = (input: number) => {
      if (input == EXCEL_TEMPLATE_INPUT.USER_NAME) {
        return "login";
      } else if (input == EXCEL_TEMPLATE_INPUT.USER_SEAL) {
        return "login";
      } else if (input == EXCEL_TEMPLATE_INPUT.NUMBER) {
        return "0";
      } else {
        return "";
      }
    };

    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="入力方法" />}
          value={stateForm.input}
          onChange={(e) => {
            if (typeof e.target.value != "string") {
              setStateForm({
                ...stateForm,
                input: e.target.value,
                maxlen: "",
                multiline: false,
                lineRows: 1,
                default: getDefaultValue(e.target.value),
                master_coordinate:
                  e.target.value >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                    ? listSelectedMaster[
                        e.target.value - EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                      ].master_coordinate
                    : undefined,
                master_attr:
                  e.target.value >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                    ? listSelectedMaster[
                        e.target.value - EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM
                      ].master_attr
                    : undefined,
                image: undefined,
                isDraw: false,
                calcRound: EXCEL_ROUND[0].value, // 四捨五入
                calcRoundPlace: 0,
              });
            }
          }}
        >
          <MenuItem value={EXCEL_TEMPLATE_INPUT.TEXT}>テキスト入力</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.MASTER}>マスタ</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.DATE}>日付</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.IMAGE}>画像</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.REPORT_NO}>帳票番号</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.USER_NAME}>
            アカウント名
          </MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.USER_SEAL}>電子印</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.APPROVED_SEAL}>承認印</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.APPROVED_DATE}>
            承認日付
          </MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.SIGNATURE}>サイン入力</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.DRAW_IMAGE}>画像設定</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.CALC}>計算</MenuItem>
          <MenuItem value={EXCEL_TEMPLATE_INPUT.NUMBER}>数値入力</MenuItem>
          {listSelectedMaster.map((v, i) => (
            <MenuItem value={v.coordinate} key={i}>
              {v.custom_item_name}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm, formError, listSelectedMaster]);

  const DefaultInput = useMemo(() => {
    switch (stateForm?.input) {
      case EXCEL_TEMPLATE_INPUT.DATE:
        return (
          <SelectLabel
            label="初期値"
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
            value={stateForm.default}
          >
            <MenuItem value="">
              <em>未選択</em>
            </MenuItem>
            <MenuItem value="today">起票日(システム日付)</MenuItem>
          </SelectLabel>
        );
      case EXCEL_TEMPLATE_INPUT.TEXT:
        return (
          <TextField
            label="初期値"
            value={stateForm.default}
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
          />
        );
      case EXCEL_TEMPLATE_INPUT.USER_NAME:
        return (
          <FormControl>
            <InputLabel>初期値</InputLabel>
            <CheckboxLabel
              label="ログインアカウント"
              checked={stateForm.default === "login"}
              onChange={(_, checked) => {
                setStateForm({
                  ...stateForm,
                  default: checked ? "login" : "",
                });
              }}
            />
          </FormControl>
        );
      case EXCEL_TEMPLATE_INPUT.USER_SEAL:
        return (
          <SelectLabel
            label={<LabelRequired title="電子印内容" />}
            onChange={(e) => {
              setStateForm({ ...stateForm, default: e.target.value });
            }}
            value={stateForm.default}
          >
            <MenuItem value={"login"}>ログインアカウント</MenuItem>
            {excelTemplate.map((item, i) => {
              return ListSealSelectable.includes(item.input) ? (
                <MenuItem value={item.coordinate} key={i}>
                  {item.name}
                </MenuItem>
              ) : null;
            })}
          </SelectLabel>
        );
      default:
        return <></>;
    }
  }, [stateForm, formError]);

  const RequiredInput = useMemo(() => {
    return (
      stateForm && (
        <Box>
          <CheckboxLabel
            label="必須"
            checked={stateForm.required}
            onChange={(_, checked) => {
              setStateForm({ ...stateForm, required: checked });
            }}
          />
        </Box>
      )
    );
  }, [stateForm]);

  const FontSizeInput = useMemo(() => {
    return (
      stateForm && (
        <FormControl error={formError?.fontSize.length > 0}>
          <InputLabel>
            <LabelRequired title="フォントサイズ" />
          </InputLabel>
          <OutlinedInput
            value={stateForm.fontSize}
            onChange={(e) => {
              setStateForm({ ...stateForm, fontSize: e.target.value });
            }}
            endAdornment={<InputAdornment position="end">pt</InputAdornment>}
            onBlur={() => validate("fontSize")}
          />
          <FormHelperText>{formError?.fontSize}</FormHelperText>
        </FormControl>
      )
    );
  }, [stateForm, formError]);

  const FontColorInput = useMemo(() => {
    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="フォント色" />}
          onChange={(e) => {
            setStateForm({ ...stateForm, fontColor: e.target.value });
          }}
          inputProps={{
            sx: {
              display: "flex",
              alignItems: "center",
            },
          }}
          value={stateForm.fontColor}
        >
          {EXCEL_TEMPLATE_FONT_COLOR.map((item, i) => (
            <MenuItem value={item.value} key={i}>
              <ListItemIcon sx={{ mr: 1 }}>
                <Circle sx={{ color: item.value ?? "transparent" }} />
              </ListItemIcon>
              {item.text}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm, formError]);

  const ApprovalStep = useMemo(() => {
    return (
      stateForm && (
        <SelectLabel
          label={<LabelRequired title="承認ステップ" />}
          onChange={(e) => {
            setStateForm({
              ...stateForm,
              approval_step: Number(e.target.value),
            });
          }}
          value={stateForm.approval_step}
        >
          {[...Array(10)].map((_, i) => (
            <MenuItem value={i + 1} key={i}>
              {`承認ステップ${i + 1}`}
            </MenuItem>
          ))}
        </SelectLabel>
      )
    );
  }, [stateForm]);

  const ImagePageHeader = (
    label: React.ReactNode,
    field: keyof TemplateExcelDataInfo,
  ) => {
    return (
      stateForm && (
        <SelectLabel
          label={label}
          onChange={(e) => {
            setStateForm({ ...stateForm, [field]: e.target.value });
          }}
          value={stateForm[field]}
        >
          <MenuItem value={""}>
            <em>未選択</em>
          </MenuItem>
          {excelTemplate.map((item, i) => {
            return ListImagePageSelectable.includes(item.input) ? (
              <MenuItem value={item.coordinate} key={i}>
                {item.name}
              </MenuItem>
            ) : null;
          })}
        </SelectLabel>
      )
    );
  };

  return <Stack>{_renderInput()}</Stack>;
};
export default forwardRef(CreateTemplateInput);
