import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import LabelRequired from "components/atoms/LabelRequired";
import LabelValueText from "components/atoms/LabelValueText";
import SelectLabel from "components/atoms/SelectLabel";
import messages from "config/messages";
import { getDetailProjectTlog } from "@api/project";
import {
  createBarcodeLayout,
  getBarcodeLayoutDetail,
  updateBarcodeLayout,
} from "@api/barcode";
import { BarcodeLayout, TemplateExcelDataInfo } from "services/models";
import GenericTemplate from "@template/index";
import { EXCEL_TEMPLATE_INPUT } from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { Validation } from "@validation";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { getBarcodeLayoutByTemplateData, getUserInfo } from "@utils/index";
import { TYPES } from "store/types";

type IStateForm = BarcodeLayout;

type IFormError = {
  title1: string;
};

const ListBarcodeSelectable: Array<number> = [
  EXCEL_TEMPLATE_INPUT.TEXT,
  EXCEL_TEMPLATE_INPUT.MASTER,
  EXCEL_TEMPLATE_INPUT.DATE,
  EXCEL_TEMPLATE_INPUT.REPORT_NO,
  EXCEL_TEMPLATE_INPUT.USER_NAME,
  EXCEL_TEMPLATE_INPUT.APPROVED_DATE,
  EXCEL_TEMPLATE_INPUT.CALC,
  EXCEL_TEMPLATE_INPUT.NUMBER,
  EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
];

const BarcodeLayoutScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const location = useLocation<any>();
  const projectId = location.state?.project_id;
  const [stateForm, setStateForm] = useState<IStateForm>({});
  const [originData, setOriginData] = useState<IStateForm>({});
  const [formError, setFormError] = useState<IFormError>({
    title1: "",
  });
  const [sort_key, setSortKey] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [templateDataInfo, setTemplateDataInfo] = useState<
    Array<TemplateExcelDataInfo | undefined>
  >([]);
  const locationIdRef = useRef<string>("");
  const dispatch = useDispatch();

  const hasUpdateData = () => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  };

  useEffect(() => {
    setIsEdited(hasUpdateData());
  }, [stateForm, originData]);

  // データ取得 ------------------------------------------------
  const fetchBarcodeLayout = async () => {
    try {
      const res = await getBarcodeLayoutDetail(
        locationIdRef.current ?? "",
        projectId,
      );
      if (res?.data) {
        const barcode_layout = JSON.parse(res.data.display_items);
        setStateForm(_.cloneDeep(barcode_layout));
        setOriginData(_.cloneDeep(barcode_layout));
        setSortKey(res.data.SK);
      } else {
        setStateForm({});
        setOriginData({});
      }
    } catch (error: any) {
      console.log("error fetchBarcodeLayout", error);
      ModalController.show({
        message: error?.detail.message,
        visibleButton2: true,
      });
    }
  };

  const fetchDetailProject = async () => {
    try {
      const res = await getDetailProjectTlog(projectId);
      if (res?.data) {
        const dataRes = res?.data;
        var data_info: Array<TemplateExcelDataInfo | undefined> =
          dataRes?.template_project_id?.data_info ?? [];
        if (!data_info || !data_info.length) {
          data_info = [];
        }
        data_info.splice(0, 0, undefined);

        setProjectName(dataRes?.project_name);
        setTemplateDataInfo([...data_info]);
        locationIdRef.current = dataRes?.project_location_id;
      }
    } catch (error: any) {
      console.log("error fetchDetailProject", error);
      ModalController.show({
        message: error?.detail.message,
        visibleButton2: true,
      });
    }
  };

  const fetchData = async () => {
    try {
      LoadingOverlayController.show();
      await fetchDetailProject();
      await fetchBarcodeLayout();
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // ボタン制御 ------------------------------------------------
  const isActiveButton = () => {
    if (hasUpdateData()) {
      const error = Object.values(formError).some(
        (item: string) => item !== "",
      );
      if (!error) {
        return true;
      }
    }
    return false;
  };

  // 入力制御 ------------------------------------------------
  const onChangeText = (field: keyof IStateForm) => (e: SelectChangeEvent) => {
    let new_value: any = undefined;
    const coordinate = e.target.value;
    if (coordinate != "") {
      const data_info = templateDataInfo.find(
        (item) => item?.coordinate === coordinate,
      );
      if (data_info) {
        // テンプレート入力設定からバーコードレイアウトデータを作成
        new_value = getBarcodeLayoutByTemplateData(data_info);
      }
    }

    if (new_value) {
      setStateForm((prev) => ({ ...prev, [field]: new_value }));
    } else {
      let new_state_form = _.cloneDeep(stateForm);
      delete new_state_form[field];
      setStateForm(new_state_form);
    }
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IStateForm, value: any) => {
    let mess: string = "";

    switch (field) {
      case "title1":
        mess = Validation.validate({
          type: "text",
          value: value?.label,
          name: "タイトル",
          required: true,
        });
        break;
    }
    return mess;
  };

  const validationAllCheck = () => {
    let response = true;
    // エラーチェック
    const mess_title1 = validator("title1", stateForm["title1"]);
    if (mess_title1.length > 0) {
      response = false;
    }
    // エラーメッセージ更新
    setFormError({
      ...formError,
      title1: mess_title1,
    });
    return response;
  };

  // 保存 ------------------------------------------------
  const handleUpdateBarcodeLayout = async () => {
    try {
      if (!validationAllCheck()) {
        return false;
      }

      LoadingOverlayController.show();

      let res;
      let message = "";
      if (sort_key !== "") {
        // 更新
        const data = {
          SK: sort_key,
          display_items: JSON.stringify(stateForm),
        };
        res = await updateBarcodeLayout(data);
        message = messages.COMMON.MSG_COMMON_SUCCESS_001("代表項目の更新");
      } else {
        // 作成
        const data = {
          location_id: locationIdRef.current,
          project_id: projectId,
          display_items: JSON.stringify(stateForm),
        };
        res = await createBarcodeLayout(data);
        message = messages.COMMON.MSG_COMMON_SUCCESS_001("代表項目の作成");
      }

      if (res?.data) {
        const user_info = getUserInfo();
        dispatch({
          type: TYPES.SET_2D_BARCODE_LAYOUT,
          payload: { location_id: user_info.location_id },
        });
        ModalController.show({
          message: message,
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
        return true;
      }
      return false;
    } catch (error: any) {
      console.log("error handleUpdateBarcodeLayout", error);
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
      return false;
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({ onOK: handleUpdateBarcodeLayout });

  const getCoordinate = useCallback(
    (key: string) => {
      const label = stateForm[key]?.label;
      const data_info = templateDataInfo.find((item) => item?.name === label);
      return data_info ? data_info.coordinate : "";
    },
    [stateForm, templateDataInfo],
  );

  const BarcodeLabelMenuItem = useMemo(() => {
    return templateDataInfo.map((item, index) => {
      return item == undefined ? (
        <MenuItem value="" key={index}>
          <em>未選択</em>
        </MenuItem>
      ) : ListBarcodeSelectable.includes(item.input) ||
        item.input >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM ? (
        <MenuItem value={item.coordinate} key={index}>
          {item.name}
        </MenuItem>
      ) : null;
    });
  }, [templateDataInfo]);

  return (
    <GenericTemplate title="代表項目設定">
      <Stack>
        <Card>
          <CardContent>
            <Stack sx={{ pb: 0 }}>
              <LabelValueText label="帳票名" value={projectName} />
              <SelectLabel
                label={<LabelRequired title={"タイトル"} />}
                value={getCoordinate("title1")}
                onChange={onChangeText("title1")}
                onBlur={() => onValidateText("title1")}
                error={formError.title1.length > 0}
                helperText={formError.title1}
              >
                {BarcodeLabelMenuItem}
              </SelectLabel>
              {[...Array(4)].map((_, index) => {
                const name_key = index + 1;
                return (
                  <SelectLabel
                    label={`明細${name_key}`}
                    value={getCoordinate(`detail${name_key}`)}
                    onChange={onChangeText(`detail${name_key}`)}
                    key={index}
                  >
                    {BarcodeLabelMenuItem}
                  </SelectLabel>
                );
              })}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          onClick={handleUpdateBarcodeLayout}
          disabled={!isActiveButton()}
          color="secondary"
        >
          {"保存"}
        </Button>
      </Box>
    </GenericTemplate>
  );
};
export default BarcodeLayoutScreen;
