import React from "react";
import { TemplateExcelDataInfo } from "services/models";
import SignatureInput from "components/atoms/SignatureInput";

interface ISignatureInputField {
  setting: TemplateExcelDataInfo;
  value: any;
  onChange: (text: Type.ImageInfoType | undefined) => void;
  setRef: (v: any) => void;
  disabledNext?: boolean;
  nextRef?: any;
  disabledPrev?: boolean;
  prevRef?: any;
}

const SignatureInputField = ({
  setting,
  value,
  onChange,
  setRef,
  disabledNext,
  nextRef,
  disabledPrev,
  prevRef,
}: ISignatureInputField) => {
  const getUrlImage = () => {
    let url = "";
    if (value?.type === "image/svg+xml") {
      if (value?.non_Local) {
        url = value.uri_jpg ?? "";
      } else {
        url = value.uriThumb ?? "";
      }
    } else {
      url = value?.uri;
    }
    return url;
  };

  return (
    <SignatureInput
      ref={setRef}
      label={setting.name}
      required={setting.required}
      signPath={getUrlImage()}
      onChange={onChange}
      disabledNext={disabledNext}
      onOpenNext={() => {
        nextRef?.openModal();
      }}
      disabledPrev={disabledPrev}
      onOpenPrev={() => {
        prevRef?.openModal();
      }}
    />
  );
};

export default SignatureInputField;
