import { Colors } from "@template/style";

const LabelRequired = ({ title }: { title?: string }) => {
  return (
    <div>
      {title}
      <span
        style={{
          color: Colors.REQUIRE,
          fontWeight: "900",
          fontSize: 18,
          paddingLeft: 5,
        }}
        className="IgnoreExtractRuleTarget"
      >
        {"＊"}
      </span>
    </div>
  );
};

export default LabelRequired;
