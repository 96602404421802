import React from "react";
import { Box, FormControl, InputLabel } from "@mui/material";
import { createObjectURL } from "@utils/index";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import Dropzone from "components/atoms/Dropzone";
import LabelRequired from "components/atoms/LabelRequired";
import { TemplateExcelDataInfo } from "services/models";

interface IImageInputField {
  setting: TemplateExcelDataInfo;
  value: any;
  onChange: (text: Type.ImageInfoType | undefined) => void;
}

const ImageInputField = ({ setting, value, onChange }: IImageInputField) => {
  const getUrlImage = () => {
    let url = "";
    if (value?.type === "image/svg+xml") {
      if (value?.non_Local) {
        url = value.uri_jpg ?? "";
      } else {
        url = value.uriThumb ?? "";
      }
    } else {
      url = value?.uri;
    }
    return url;
  };

  return (
    <FormControl>
      <InputLabel>
        {setting.required ? (
          <LabelRequired title={setting.name} />
        ) : (
          setting.name
        )}
      </InputLabel>
      {value?.uri ? (
        <Box sx={{ textAlign: "center" }}>
          <CancelBtnBadge
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Box
              component="img"
              src={getUrlImage()}
              sx={{
                height: 140,
                objectFit: "contain",
                borderRadius: 1,
                width: "100%",
              }}
            />
          </CancelBtnBadge>
        </Box>
      ) : (
        <Dropzone
          accept="image"
          maxFiles={1}
          onChoose={(res) => {
            const uri = createObjectURL(res[0]);
            res[0]["uri"] = uri;
            onChange(res[0]);
          }}
        />
      )}
    </FormControl>
  );
};

export default ImageInputField;
