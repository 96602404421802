import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Button, Stack } from "@mui/material";
import _, { cloneDeep, toString } from "lodash";
import { useLocation } from "react-router-dom";
import {
  getProjectIdStore,
  getProjectLocationIdStore,
  getProjectTemplateIdStore,
} from "selector/projectSelector";
import messages from "config/messages";
import { RootState } from "store/reducer";
import GenericTemplate from "@template/index";
import { Project, Template, Work } from "services/models";
import { getDetailProjectTlog } from "@api/project";
import { createWorkApi, getWorkByIdApi, updateWorkByIdApi } from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { getDevanIsDelivered } from "@api/devan";
import {
  createFileNameFollowUri,
  createFormMutiplePhotoUpload,
  getFileByUrl,
} from "@utils/index";
import FormContextReport, {
  ICreateReportStateForm,
  getUserIdFromSK,
  FlowType,
} from "./FormContextReport";
import {
  STATUS_WORK_TLOG,
  TYPE_USER_REPORT,
  screenIdIsLandscape,
  screenIdSupportMergeTemplate,
  screenIdSupport,
  TEMPLATE_EDIT_TYPE,
} from "@shared-constants";
import { screenIdSupportGetImageUrl } from "./templateFunctionSupport";

interface CreateReportWork extends Work {
  work_template_id: any;
  files?: Array<{
    SK?: string;
    file_name?: string;
    file_type?: string;
    path_file?: string;
  }>;
  images?: Array<{
    SK?: string;
    file_name?: string;
    file_type?: string;
    path_file?: string;
    path_file_thumb?: string;
    file_size?: string;
  }>;
  project_id: string;
  version: number;
  flow_approve_data?: Type.FlowDataType;
  is_hand_over?: boolean;
}

const GOBACK_2_REFERRER = ["/select-report"];
const GOBACK_3_REFERRER = ["/select-report/handover"];

const CreateReportTlogScreen = ({ route, navigation }: any) => {
  // const project_id = useSelector(
  //   (state: RootState) => state.projectInfo.selected_project_id,
  // );
  const is_daily = useSelector(
    (state: RootState) => state.projectInfo.is_daily,
  );
  const template_id = useSelector(getProjectTemplateIdStore);
  const location_id = useSelector(getProjectLocationIdStore);
  const [isUseDevanSchedule, setIsUseDevanSchedule] = useState(false);
  const location = useLocation<any>();
  const reportCopy: Work = location.state.workData;
  const data: CreateReportWork = location.state.data;
  const is_from_handover_report: boolean | undefined =
    location.state.is_from_handover_report;
  const userType: string = location.state.userType;
  const mail_work_id: string = location.state?.mail_work_id ?? "";
  const project_id = data?.project_id ?? useSelector(getProjectIdStore);
  const is_inactive = userType == TYPE_USER_REPORT.APPROVE;
  const reject = data?.work_status == "REJECT";
  const is_final_approval = data?.work_template_id?.is_final_approval;
  const stateFormInit: ICreateReportStateForm = reject
    ? {
        work_name: data.work_name,
        category_id: "",
        memo: data.memo,
        work_template_id: data?.work_template_id
          ? `TEMPLATE_WORK#${data?.work_template_id?.template_id}`
          : template_id,
        template_file: {
          type: "",
          uri: "",
          name: "",
          templateEditInfo: data?.work_template_id
            ? JSON.parse(data?.work_template_id?.work_template_data ?? "null")
            : null,
        },
        templateInfo: {
          name: data?.work_template_id?.template_name ?? null,
          file: data?.work_template_id?.path_file ?? null,
          screen_id: data?.work_template_id?.screen_id ?? "default",
        },
        work_status: STATUS_WORK_TLOG.NOT_APPROVED,
        work_start_date: data.work_start_date,
        work_end_date: data.work_end_date,
      }
    : data?.work_status == STATUS_WORK_TLOG.DRAFT
    ? {
        work_name: data.work_name,
        category_id: "",
        memo: data.memo,
        work_template_id: data?.work_template_id
          ? `TEMPLATE_WORK#${data?.work_template_id?.template_id}`
          : template_id,
        template_file: {
          type: "",
          uri: "",
          name: "",
          templateEditInfo: data?.work_template_data
            ? JSON.parse(data?.work_template_data ?? "null")
            : null,
        },
        templateInfo: {
          name: data?.work_template_id?.template_name ?? null,
          file: data?.work_template_id?.path_file ?? null,
          screen_id: data?.work_template_id?.screen_id ?? "default",
        },
        work_status: STATUS_WORK_TLOG.NOT_APPROVED,
        work_start_date: data.work_start_date,
        work_end_date: data.work_end_date,
        is_hand_over: is_from_handover_report || data.is_hand_over,
      }
    : reportCopy
    ? {
        work_name: reportCopy.work_name,
        category_id: "",
        memo: "",
        work_template_id: template_id,
        template_file: {
          type: "",
          uri: "",
          name: "",
          templateEditInfo: null,
        },
        templateInfo: {
          name: "",
          file: "",
        },
        work_status: STATUS_WORK_TLOG.NOT_APPROVED,
        work_start_date: reportCopy.work_start_date,
        work_end_date: reportCopy.work_end_date,
        work_inprogress_id: "",
      }
    : {
        work_name: "",
        category_id: "",
        memo: "",
        work_template_id: template_id,
        template_file: {
          type: "",
          uri: "",
          name: "",
          templateEditInfo: null,
        },
        work_status: STATUS_WORK_TLOG.NOT_APPROVED,
        work_start_date: "",
        work_end_date: "",
        work_inprogress_id: "",
        is_first_create: true,
      };
  const [stateForm, setStateForm] = useState(stateFormInit);
  const [stateFormDefault, setStateFormDefault] = useState(stateFormInit);

  const [riskyId, setRiskyId] = useState<string>();
  const [selectedFiles, setSelectedFiles] = useState<Object[]>([]);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  // const [fileDelete, setFileDelete] = useState([]);
  // const [imageDelete, setImageDelete] = useState([]);
  const [disabled, setDisable] = useState<boolean>(true);

  const [template, setTemplate] = useState<Template>();
  const [project, setProject] = useState<Project>();

  const [version, setVersion] = useState(1);

  const refFormContext = useRef<any>(null);

  const getMesssageErr = () => {
    let response: string[] = [];
    if (refFormContext.current) {
      const formError = refFormContext.current.formError;
      if (formError) {
        response = Object.values<string>(formError);
      }
    }
    return response;
  };

  const handleCreateReport = async (
    project_id: string,
    status_create?: string,
    isButton?: boolean,
    is_final_approval?: boolean,
    is_error_request?: boolean,
    flow_type?: keyof typeof FlowType,
  ) => {
    try {
      LoadingOverlayController.show();
      /*** バリデーション ***/
      if (refFormContext.current) {
        switch (flow_type) {
          case FlowType.DAILY:
            if (
              !refFormContext.current.listDailyApprove ||
              !refFormContext.current.listDailyApproveDefault
            ) {
              ModalController.show({
                message: messages.WORK_ITEM.MSG_ERROR_NOT_LIST_APPROVED,
                visibleButton2: true,
              });
              return false;
            }
            break;
          case FlowType.FINAL:
            if (
              !refFormContext.current.listFinalApprove ||
              !refFormContext.current.listFinalApproveDefault
            ) {
              ModalController.show({
                message: messages.WORK_ITEM.MSG_ERROR_NOT_LIST_APPROVED,
                visibleButton2: true,
              });
              return false;
            }
            break;
          default:
        if (
          !refFormContext.current.listApprove ||
          !refFormContext.current.listApproveDefault
        ) {
          ModalController.show({
            message: messages.WORK_ITEM.MSG_ERROR_NOT_LIST_APPROVED,
            visibleButton2: true,
          });
          return false;
        }
            break;
        }

        if (
          status_create !== STATUS_WORK_TLOG.DRAFT &&
          refFormContext.current.validationAllCheck()
        ) {
          return false;
        }
      }

      if (is_error_request == undefined) {
        is_error_request = false;
      }

      let listImageAdd: any = [];
      let listFileAdd: any = [];

      let flow = { SK: "" };
      let flowData: Type.FlowDataType = [];
      let listUserStep: Array<Array<Type.DataStepUserType>> = [];
      let listApproveDefault: Type.FlowDataType = [];

      if (refFormContext.current) {
        switch (flow_type) {
          case FlowType.DAILY:
            flowData = cloneDeep(refFormContext.current.listDailyApprove);
            listUserStep = cloneDeep(refFormContext.current.listDailyUserStep);
            listApproveDefault = cloneDeep(
              refFormContext.current.listDailyApproveDefault,
            );
            flow = cloneDeep(refFormContext.current.dailyFlow);
            break;
          case FlowType.FINAL:
            flowData = cloneDeep(refFormContext.current.listFinalApprove);
            listUserStep = cloneDeep(refFormContext.current.listFinalUserStep);
            listApproveDefault = cloneDeep(
              refFormContext.current.listFinalApproveDefault,
            );
            flow = cloneDeep(refFormContext.current.finalFlow);
            break;
          default:
        flowData = cloneDeep(refFormContext.current.listApprove);
        listUserStep = cloneDeep(refFormContext.current.listUserStep);
        listApproveDefault = cloneDeep(
          refFormContext.current.listApproveDefault,
        );
        flow = cloneDeep(refFormContext.current.flow);
            break;
        }
      }
      listUserStep.map((listStep, indexListStep) => {
        if (listStep) {
          flowData[indexListStep].data_step = listStep.map((user, index) => {
            return {
              PK: listApproveDefault[indexListStep]?.data_step[0].PK,
              approver_id:
                listApproveDefault[indexListStep]?.data_step[0].approver_id,
              created_at:
                listApproveDefault[indexListStep]?.data_step[0].created_at,
              group_id:
                listApproveDefault[indexListStep]?.data_step[0].group_id,
              location_id:
                listApproveDefault[indexListStep]?.data_step[0].location_id,
              seq: listApproveDefault[indexListStep]?.data_step[0].seq,
              timestamp:
                listApproveDefault[indexListStep]?.data_step[0].timestamp,
              updated_at:
                listApproveDefault[indexListStep]?.data_step[0].updated_at,
              user_approver_id: getUserIdFromSK(user.SK),
              SK: `${listApproveDefault[indexListStep]?.data_max_user?.step_id}|${user.SK}`,
              avatar: user?.avatar,
              full_name: user?.full_name,
              is_approved: false,
              isDefault: false,
            };
          });
        }
      });

      //InspectionReportかつ作業の状態がRejectではないとき、使用しているデバンの搬入状態を確認する
      if (isUseDevanSchedule && !reject) {
        if (stateForm?.template_file?.templateEditInfo?.devan_schedule_id) {
          let response = await getDevanIsDelivered({
            container_sort_key:
              stateForm?.template_file?.templateEditInfo?.devan_schedule_id,
          });
          if (response?.data) {
            //Trueの場合は、搬入済み
            ModalController.show({
              message: messages.WORK_ITEM.MSG_DEVAN_IS_DELIVERED,
              visibleButton2: true,
            });
            return false;
          }
        }
      }
      const dataForm =
        reject || data?.work_status == STATUS_WORK_TLOG.DRAFT
          ? {
              category_id: stateForm.category_id ?? "null",
              work_template_id: stateForm.work_template_id,
              template_work_sk:
                data.work_template_id?.SK ?? stateForm?.template_work_sk,
              work_status: status_create ?? stateForm.work_status,
              work_start_date: stateForm.work_start_date
                ? stateForm.work_start_date
                : "null",
              work_end_date: stateForm.work_end_date
                ? stateForm.work_end_date
                : "null",
              template_delete: stateForm.work_template_id
                ? ""
                : data.work_template_id?.SK,
              page_number: toString(true),
              is_landscape: toString(
                template?.screen_id
                  ? screenIdIsLandscape[template?.screen_id] ??
                      template?.is_landscape ??
                      false
                  : false,
              ),
              flow_id: flow?.SK,
              is_final_approval: is_final_approval,
              is_hand_over: stateForm.is_hand_over,
              is_use_devan_schedule: isUseDevanSchedule,
              is_error_request: is_error_request,
              // flow_data: flowData,
            }
          : {
              category_id: stateForm.category_id,
              work_template_id: stateForm.work_template_id,
              work_status: status_create ?? stateForm.work_status,
              work_start_date: stateForm.work_start_date,
              work_end_date: stateForm.work_end_date,
              risky_id: riskyId,
              location_id: location_id,
              work_inprogress_id: stateForm.work_inprogress_id,
              page_number: toString(true),
              is_landscape: toString(
                template?.screen_id
                  ? screenIdIsLandscape[template?.screen_id] ??
                      template?.is_landscape ??
                      false
                  : false,
              ),
              flow_id: flow?.SK,
              is_final_approval: is_final_approval,
              is_hand_over: stateForm.is_hand_over,
              is_use_devan_schedule: isUseDevanSchedule,
              is_error_request: is_error_request,
              // flow_data: flowData,
            };
      const formData =
        reject || data?.work_status == STATUS_WORK_TLOG.DRAFT
          ? await createFormMutiplePhotoUpload(
              listImageAdd,
              listFileAdd,
              dataForm,
            )
          : await createFormMutiplePhotoUpload([], [], dataForm);
      formData.append("category_id", "");
      formData.append("work_start_date", "");
      formData.append("work_end_date", "");
      formData.append("flow_data", JSON.stringify({ data: flowData }));

      if (stateForm?.template_file?.uri && stateForm?.work_template_id) {
        const f = await getFileByUrl(
          stateForm?.template_file?.uri,
          stateForm?.template_file?.name,
          stateForm?.template_file?.type,
        );
        formData.append("work_template_file", f);

        if (stateForm?.template_file?.templateEditInfo) {
          formData.append(
            "work_template_data",
            JSON.stringify(stateForm?.template_file?.templateEditInfo),
          );
        } else formData.append("work_template_data", JSON.stringify(null));
      }
      const res =
        reject || data?.work_status == STATUS_WORK_TLOG.DRAFT
          ? await updateWorkByIdApi(
              data?.project_id,
              data?.SK.startsWith("WORK#") ? data?.SK : data?.PK,
              formData,
            )
          : await createWorkApi(formData, { project_id });

      if (res?.data) {
        ModalController.show({
          message: messages.WORK_ITEM.MSG_CREATE_SUCCESS(res.risky_id),
          visibleButton2: true,
          handlePressButton2: () => {
            if (isButton) {
              if (mail_work_id !== "") {
                window.location.href = "/";
              } else if (GOBACK_3_REFERRER.includes(location.state.referrer)) {
                history.go(-3);
              } else if (
                reject ||
                reportCopy ||
                GOBACK_2_REFERRER.includes(location.state.referrer)
              ) {
                history.go(-2);
              } else {
                history.go(-1);
              }
            } else {
              if (mail_work_id !== "" || is_from_handover_report) {
                window.location.href = "/";
              } else if (GOBACK_3_REFERRER.includes(location.state.referrer)) {
                history.go(-2);
              } else if (
                reject ||
                reportCopy ||
                GOBACK_2_REFERRER.includes(location.state.referrer)
              ) {
                history.go(-1);
              } else {
                history.go();
              }
            }
          },
        });
        setDisable(true);

        if (isButton) {
          setIsEdited(false);
          return false;
        } else if (gobackRef.current) {
          setIsEdited(false);
          return false;
        }

        return true;
      }
      return false;
    } catch (error: any) {
      console.log("error createWork", error);
      if (error?.detail?.code == 8) {
        ModalController.show({
          message: error?.detail?.message,
          visibleButton2: true,
        });
      } else if (error?.detail?.message) {
        ModalController.show({
          message: error?.detail?.message,
          visibleButton2: true,
        });
      } else {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
        });
      }
      return false;
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const checkInspectionFailed = (
    flow_type: keyof typeof FlowType,
    is_final_approval: boolean,
  ) => {
    if (
      template &&
      screenIdSupport[template.screen_id] !==
        TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT
    ) {
      // 日次フォークリフト点検簿以外はチェックしない
      return false;
    }

    if (stateForm?.template_file?.templateEditInfo) {
      const check_result =
        stateForm?.template_file?.templateEditInfo.check_result;
      let max_date = Math.max(
        ...Object.keys(check_result).map((d) => Number(d)),
      );
      const is_failed = Object.values(check_result[max_date].result).some(
        (item) => item === false,
      );
      if (is_failed) {
        ModalController.show({
          message: messages.WORK_ITEM.MSG_CONFIRM_EMAIL_INSPECTION_FAILD,
          button1: { title: messages.COMMON.BUTTON.CANCEL },
          button2: { title: messages.COMMON.BUTTON.CONFIRM },
          visibleButton1: true,
          visibleButton2: true,
          handlePressButton1: () =>
            createDailyReport(false, is_final_approval, flow_type),
          handlePressButton2: () =>
            createDailyReport(true, is_final_approval, flow_type),
        });
        return true;
      }
    }

    return false;
  };

  const confirmFinalApproval = () => {
    ModalController.show({
      message: messages.WORK_ITEM.MSG_CONFIRM_FINAL_APPROVED_WORK,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => {
        const interval = setInterval(() => {
          if (!ModalController.isShowing()) {
            onClickDailySave("FINAL", true);
            clearInterval(interval);
        }
        }, 100);
      },
      button1: { title: messages.COMMON.BUTTON.CANCEL },
    });
  };

  const hasUpdateData = () => {
    if (
      !_.isEqual(stateFormDefault, stateForm) ||
      selectedFiles.length ||
      selectedImages.length
      // || selectedUser.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const messsageErr = getMesssageErr();
    const error = messsageErr.filter((item: string) => item !== "").length > 0;
    if (
      stateForm.work_name &&
      // stateForm.category_id &&
      stateForm.work_status &&
      // selectedUser.length &&
      !error
    ) {
      setDisable(false);
    } else setDisable(true);
  }, [stateForm]);

  const getImagesAndDetailproject = async (project_id: string) => {
    LoadingOverlayController.show();
    try {
      let newStateForm = stateFormInit;
    if (data?.work_status == STATUS_WORK_TLOG.DRAFT) {
      setRiskyId(data?.risky_id);
      const workDraft = await getWorkCopy(data);
      if (workDraft) {
        if (workDraft?.work_template_id) {
            newStateForm = {
            ...stateForm,
            work_template_id: workDraft?.work_template_id
              ? `TEMPLATE_WORK#${workDraft?.work_template_id?.template_id}`
              : template_id,
            template_file: {
              type: "",
              uri: "",
              name: "",
              templateEditInfo: workDraft?.work_template_id
                ? JSON.parse(
                    workDraft?.work_template_id?.work_template_data ?? "null",
                  )
                : null,
            },
            templateInfo: {
              name: workDraft?.work_template_id?.template_name ?? null,
              file: workDraft?.work_template_id?.path_file ?? null,
            },
            template_work_sk: workDraft.work_template_id?.SK,
            };
        }
        if (workDraft?.flow_approve_data) {
          if (refFormContext.current) {
            refFormContext.current.changeListApprove(
              workDraft?.flow_approve_data,
              typeof workDraft?.flow_approve_id == "string"
                ? workDraft?.flow_approve_id
                : workDraft?.flow_approve_id?.SK,
            );
          }
        }
        if (workDraft?.is_increase_version) {
          setVersion(+workDraft?.version + 1);
        }
        if (is_from_handover_report) {
          //引継ぎ帳票から遷移してきた場合
          setVersion(+workDraft?.version + 1);
        }
      }
    }
    if (reject) {
      setRiskyId(data?.risky_id);
      setVersion(+data?.version + 1);
    }

      const res = await getDetailProjectTlog(project_id);
      if (res?.data) {
        const templateInfo: Template = res.data?.template_project_id;
        setIsUseDevanSchedule(res.data?.is_use_devan_schedule ?? false);
        setTemplate(templateInfo);
        setProject(res.data);

        if (templateInfo?.template_path) {
          const fileName = createFileNameFollowUri(templateInfo?.template_path);
          let localFile = templateInfo?.template_path;
          let newStateFormEditInfo =
            stateForm?.template_file?.templateEditInfo ?? null;

          if (stateForm?.templateInfo && newStateFormEditInfo) {
            if (reject || data?.work_status == STATUS_WORK_TLOG.DRAFT) {
              newStateFormEditInfo = await screenIdSupportGetImageUrl[
                templateInfo.screen_id
              ](newStateFormEditInfo);
            }
            const newPath = await screenIdSupportMergeTemplate[
              templateInfo?.screen_id
            ](localFile, newStateFormEditInfo, templateInfo);

            newStateForm = {
              ...newStateForm,
              template_file: {
                type: "text/html",
                uri: newPath,
                name: fileName,
                templateEditInfo: newStateFormEditInfo ?? null,
              },
            };
          } else {
            newStateForm = {
              ...newStateForm,
              template_file: {
                type: "text/html",
                uri: localFile,
                name: fileName,
                templateEditInfo:
                  stateForm?.template_file?.templateEditInfo ?? null,
              },
            };
          }
          setStateFormDefault(newStateForm);
          setStateForm(newStateForm);
        }
      }
    } catch (e) {
      console.log("err :>> ", e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const createDailyReport = (
    is_error_request: boolean,
    is_final_approval: boolean,
    flow_type: keyof typeof FlowType,
  ) => {
    if (userType === TYPE_USER_REPORT.APPROVE) {
      handleCreateReport(
        project_id,
        STATUS_WORK_TLOG.FINISH,
        true,
        is_final_approval,
        is_error_request,
        flow_type,
      );
      return;
    }
    handleCreateReport(
      project_id,
      undefined,
      true,
      is_final_approval,
      is_error_request,
      flow_type,
    );
  };

  const getWorkCopy = async (data: any) => {
    try {
      const res = await getWorkByIdApi({
        project_id: data?.project_id,
        work_id: data?.SK.startsWith("WORK#") ? data.SK : data.PK,
      });
      return res.data;
    } catch (e: any) {
      ModalController.show({
        message: e?.response?.detail.message,
        visibleButton2: true,
      });
    }
  };

  const setCopy = async () => {
    let workCopy: any;
    if (reportCopy) {
      workCopy = await getWorkCopy(reportCopy);
    }
    if (workCopy) {
      if (workCopy?.work_template_id) {
        setStateForm({
          ...stateForm,
          work_template_id: workCopy?.work_template_id
            ? `TEMPLATE_WORK#${workCopy?.work_template_id?.template_id}`
            : template_id,
          template_file: {
            type: "",
            uri: "",
            name: "",
            templateEditInfo: workCopy?.work_template_id
              ? JSON.parse(
                  workCopy?.work_template_id?.work_template_data ?? "null",
                )
              : null,
          },
          templateInfo: {
            name: workCopy?.work_template_id?.template_name ?? null,
            file: workCopy?.work_template_id?.path_file ?? null,
          },
        });
      }
      if (workCopy?.flow_approve_data) {
        if (refFormContext.current) {
          refFormContext.current.changeListApprove(
            workCopy?.flow_approve_data,
            typeof workCopy?.flow_approve_id == "string"
              ? workCopy?.flow_approve_id
              : workCopy?.flow_approve_id?.SK,
          );
        }
      }
    }
  };

  const getAndHandleData = async () => {
    try {
      LoadingOverlayController.show();
      if (data?.flow_approve_data) {
        if (refFormContext.current) {
          refFormContext.current.changeListApprove(
            data?.flow_approve_data,
            typeof data?.flow_approve_id == "string"
              ? data?.flow_approve_id
              : data?.flow_approve_id?.SK,
          );
        }
      }
      await setCopy();
      await getImagesAndDetailproject(project_id);
    } catch (err) {
      console.log("fail to get data for create wwork screen", err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getAndHandleData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // 変更有無をチェック
    setIsEdited(hasUpdateData());
  }, [stateForm, selectedFiles, selectedImages, stateFormDefault]);

  // 保存確認ポップアップ
  const { setIsEdited, gobackRef } = useRouterPrompt({
    onOK: () => handleCreateReport(project_id, STATUS_WORK_TLOG.DRAFT),
    onCancel: () => {
      if (reject || reportCopy) {
        if (gobackRef.current) {
          window.history.pushState(null, "", null);
          setIsEdited(false);
          setTimeout(() => {
            if (mail_work_id !== "") {
              window.location.href = "/";
            } else {
              window.history.go(-2);
            }
          }, 10);
          // window.history.go(-1);
          return false;
        }
      }
      return true;
    },
  });

  const getWorkId = () => {
    if (data) return data?.SK?.startsWith("WORK#") ? data?.SK : data?.PK;
    if (reportCopy)
      return reportCopy?.SK?.startsWith("WORK#")
        ? reportCopy?.SK
        : reportCopy?.PK;
    return null;
  };

  const onClickSave = () => {
    if (userType === TYPE_USER_REPORT.APPROVE) {
      handleCreateReport(
        project_id,
        STATUS_WORK_TLOG.FINISH,
        true,
        is_final_approval,
      );
      return;
    }
    handleCreateReport(project_id, undefined, true, is_final_approval);
  };

  const onClickDailySave = (
    flow_type: keyof typeof FlowType,
    is_final_approval: boolean,
  ) => {
    const inspection_failed = checkInspectionFailed(
      flow_type,
      is_final_approval,
    );
    if (!inspection_failed) {
      createDailyReport(false, is_final_approval, flow_type);
    }
  };

  return (
    <GenericTemplate title="報告作成">
      <Stack>
        <FormContextReport
          riskyId={riskyId ?? ""}
          stateForm={stateForm}
          setStateForm={setStateForm}
          template={template}
          workId={getWorkId()}
          is_inactive={is_inactive}
          userType={userType}
          version={version}
          ref={refFormContext}
        />
      </Stack>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        {!is_inactive && (
          <Button
            sx={{ mr: 1 }}
            onClick={async () => {
              handleCreateReport(project_id, STATUS_WORK_TLOG.DRAFT, true);
            }}
          >
            一時保存
          </Button>
        )}
        {reject && userType === TYPE_USER_REPORT.APPROVE ? (
          <Button onClick={onClickSave} color="secondary">
            承認
          </Button>
        ) : is_daily ? (
          <>
            <Button
              sx={{ mr: 1 }}
              onClick={() => onClickDailySave("DAILY", false)}
              color="secondary"
            >
              日次申請
            </Button>
            <Button sx={{ mr: 1 }} onClick={confirmFinalApproval} color="error">
              最終申請
            </Button>
          </>
        ) : (
          <Button onClick={onClickSave} color="secondary">
            申請
          </Button>
        )}
      </Box>
    </GenericTemplate>
  );
};

export default CreateReportTlogScreen;
