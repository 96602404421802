import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Card, CardContent, Stack } from "@mui/material";
import { cloneDeep, debounce, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { getProjectLocationIdStore } from "selector/projectSelector";
import AccordionSection from "components/molecules/AccordionSection";
import { getListUserByLocationId } from "@api/User";
import { getListCustomMaster, getMasterType } from "@api/masterType";
import { TemplateExcelDataInfo } from "services/models";
import formatDateToString from "@utils/DateFormat";
import { getUserInfo } from "@utils/index";
import {
  IMasterValue,
  StateFormType,
  getExcelTemplateCalcText,
  getImageArray,
  getInfoFromTemplate,
  insertReportLocal,
  makeNewHtml,
} from "@utils/template/excelTemplate";
import { STORAGE, storeData } from "@utils/Storage";
import { uploadImageToS3 } from "@utils/template";
import { Validation } from "@validation";
import { getNewReportItem } from "sagas/setting";
import {
  CUSTOM_MASTER_NAME_ATTRIBUTE,
  EXCEL_TEMPLATE_INPUT,
  EXCEL_TEMPLATE_MASTER_NAME,
} from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import TextInputField from "./InputField/TextInputField";
import MasterInputField from "./InputField/MasterInputField";
import DateInputField from "./InputField/DateInputField";
import ImageInputField from "./InputField/ImageInputField";
import ImagePageField from "./InputField/ImagePageField";
import AccountInputField from "./InputField/AccountInputField";
import SignatureInputField from "./InputField/SignatureInputField";
import DrawImageInputField from "./InputField/DrawImageInputField";
import NumberInputField from "./InputField/NumberInputField";

interface ITemplateExcelReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  inputSetting: Array<TemplateExcelDataInfo>;
  open: boolean;
  isSetting: boolean;
  activityBaseId?: string;
}

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const masterDefault: IMasterValue = {
  id: null,
  name: "",
  is_manual_input: false,
};

const list_input_not_disp = [
  EXCEL_TEMPLATE_INPUT.REPORT_NO,
  EXCEL_TEMPLATE_INPUT.USER_SEAL,
  EXCEL_TEMPLATE_INPUT.APPROVED_SEAL,
  EXCEL_TEMPLATE_INPUT.APPROVED_DATE,
  EXCEL_TEMPLATE_INPUT.CALC,
  EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
];

const TemplateExcelReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    inputSetting,
    open,
    isSetting,
    activityBaseId,
  }: ITemplateExcelReport,
  ref: React.Ref<unknown>,
) => {
  const [stateForm, setStateForm] = useState<StateFormType>({});
  const [originStateForm, setOriginStateForm] = useState<StateFormType>({});
  const [formError, setFormError] = useState<{ [key: string]: string }>({});
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [listReportItem, setListReportItem] = useState<any>(null);
  const [listUser, setListUser] = useState<any>(null);
  const [listMasterType, setListMasterType] = useState<any>({});
  const user_info = getUserInfo();
  const location_id = useSelector(getProjectLocationIdStore);
  const signatureRef = useRef<any>({});

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.image_array?.map((item: any) => {
        delete item.base64;
      });
      inputSetting
        .filter((setting) => setting.input === EXCEL_TEMPLATE_INPUT.CALC)
        .forEach((setting) => {
          cloneStateForm[setting.coordinate] = getExcelTemplateCalcText(
            cloneStateForm,
            setting,
            inputSetting,
          );
        });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    disabledSubmit: () => Object.values(formError).some((item) => item != ""),
    onSubmit: onSubmit,
  }));

  // データ取得 =====================================
  useEffect(() => {
    getData();
    return () => {
      setCompleteGetData(false);
    };
  }, [inputSetting]);

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      // マスタ入力がある場合、マスタデータを取得
      let master: any = [];
      let master_type: any = [];
      let hasMaster = inputSetting.some(
        (item) => item.input === EXCEL_TEMPLATE_INPUT.MASTER,
      );
      if (hasMaster) {
        const res = await getMasterData();
        master = res.resReportItem;
        master_type = res.resMasterType;
      }

      // アカウント名がある場合、マスタデータを取得
      let hasUser = inputSetting.some(
        (item) => item.input === EXCEL_TEMPLATE_INPUT.USER_NAME,
      );
      if (listUser === null && hasUser) {
        await getListUser();
      }

      let new_state_form: { [key: string]: any } = {};
      let new_form_error: { [key: string]: string } = {};
      inputSetting.forEach((item) => {
        let value: any = undefined;
        if (item.default) {
          value = item.default;
          if (
            item.input == EXCEL_TEMPLATE_INPUT.DATE &&
            item.default == "today"
          ) {
            value = formatDateToString(new Date(), "YMD_sl");
          }
        }
        if (item.input == EXCEL_TEMPLATE_INPUT.MASTER) {
          const list = master[item.master] ? master[item.master] : [];
          const nameField =
            EXCEL_TEMPLATE_MASTER_NAME[item.master] ??
            CUSTOM_MASTER_NAME_ATTRIBUTE;
          const index_default = list.findIndex(
            (item: any) => item?.is_default === true,
          );
          const custom_item: any[] =
            master_type[item.master] && master_type[item.master]?.custom_item
              ? master_type[item.master].custom_item
              : [];
          if (index_default !== -1) {
            value = {
              id: list[index_default].SK,
              name: list[index_default][nameField],
              is_manual_input: list[index_default].is_manual_input,
            };
            // マスタ追加項目をマスタデータに追加
            if (custom_item) {
              custom_item.forEach((v, index) => {
                let key = v.barcode ? "barcode_item" : `item${index + 1}`;
                value[key] = list[index_default][key];
              });
            }
          } else {
            value = { ...masterDefault };
            // マスタ追加項目をマスタデータに追加
            if (custom_item) {
              custom_item.forEach((v, index) => {
                let key = v.barcode ? "barcode_item" : `item${index + 1}`;
                value[key] = "";
              });
            }
          }
        }
        if (item.input == EXCEL_TEMPLATE_INPUT.IMAGE) {
          value = { ...imageDefault };
        }
        if (item.input == EXCEL_TEMPLATE_INPUT.USER_NAME) {
          if (item.default == "login") {
            value = {
              id: user_info.SK,
              name: user_info.full_name,
              is_manual_input: false,
            };
          } else {
            value = { ...masterDefault };
          }
        }
        if (item.input == EXCEL_TEMPLATE_INPUT.USER_SEAL) {
          if (item.default == "login") {
            value = user_info.SK;
          } else {
            value = "";
          }
        }
        if (item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE) {
          value = { ...item.image };
        }
        new_state_form[item.coordinate] = value;
        new_form_error[item.coordinate] = "";
      });

      new_state_form["image_array"] = [];

      setStateForm(new_state_form);
      setOriginStateForm(new_state_form);
      setFormError(new_form_error);
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getMasterData = async () => {
    let resReportItem: any = cloneDeep(listReportItem);
    let resMasterType: any = cloneDeep(listMasterType);
    const param_location = activityBaseId ?? location_id;

    // マスタの更新チェック
    if (listReportItem === null) {
      resReportItem = await getNewReportItem(param_location);
      storeData(STORAGE.ALL_REPORT_ITEM, JSON.stringify(resReportItem));
    }
    // ユーザー作成マスタ種類を取得
    await getMasterType(param_location).then((res) => {
      if (res?.data) {
        const master_type_data: any[] = res.data;
        master_type_data.forEach((v) => {
          resMasterType[v.SK.replace(param_location + "|", "")] = v;
        });
      }
    });
    // ユーザー作成マスタ取得
    const list_user_master = inputSetting.filter(
      (item) =>
        item.input === EXCEL_TEMPLATE_INPUT.MASTER &&
        !Object.keys(resReportItem).includes(item.master),
    );
    for (let index = 0; index < list_user_master.length; index++) {
      const item = list_user_master[index];
      if (Object.keys(resReportItem).includes(item.master)) {
        // 取得済みの場合は何もしない
        continue;
      }
      if (
        resMasterType[item.master] &&
        resMasterType[item.master].custom_item &&
        resMasterType[item.master].custom_item.some((v: any) => v.barcode)
      ) {
        // バーコード検索の場合は何もしない
        continue;
      }
      await getListCustomMaster(param_location + "|" + item.master).then(
        (res) => {
          if (res?.data) {
            resReportItem[item.master] = res.data;
          }
        },
      );
    }

    setListReportItem(resReportItem);
    setListMasterType(resMasterType);
    return { resReportItem, resMasterType };
  };

  const getListUser = async () => {
    // アカウント一覧データ取得
    let resListUser = await getListUserByLocationId();
    const list_user = resListUser ?? [];
    setListUser(list_user);
    return list_user;
  };

  useEffect(() => {
    if (!open || !completeGetData) return;
    if (isSetting) {
      setStateForm((prev) => ({
        ...prev,
        ...cloneDeep(infoToEdit),
      }));
      setOriginStateForm((prev) => ({
        ...prev,
        ...cloneDeep(infoToEdit),
      }));
    } else if (isUpdate) {
      setStateForm(cloneDeep(infoToEdit));
      setOriginStateForm(cloneDeep(infoToEdit));
    } else {
      setStateForm((prev) => ({
        ...prev,
        risky_id: infoToEdit?.risky_id ?? "",
      }));
      setOriginStateForm((prev) => ({
        ...prev,
        risky_id: infoToEdit?.risky_id ?? "",
      }));
    }
  }, [open, completeGetData, listReportItem]);

  const isUpdate = useMemo(() => {
    return (
      infoToEdit &&
      inputSetting.length > 0 &&
      Object.keys(infoToEdit).findIndex(
        (key) => inputSetting.findIndex((item) => item.coordinate == key) >= 0,
      ) >= 0
    );
  }, [infoToEdit, inputSetting]);

  // テンプレートHTML =====================================
  const getHtmlString = useCallback(
    async (
      state_form: any,
      input_setting: Array<TemplateExcelDataInfo>,
      html_string: string,
    ) => {
      const res = getInfoFromTemplate(html_string);
      const image_array = await getImageArray(state_form.image_array);
      let newHtml: string = await makeNewHtml(
        html_string,
        state_form,
        input_setting,
      );

      newHtml = insertReportLocal(
        newHtml,
        state_form,
        input_setting,
        res.imageContentExample,
        res.pageImageExample,
        image_array,
      );
      setHtmlString(newHtml);

      if (!isEqual(image_array, state_form.image_array)) {
        setStateForm({ ...state_form, image_array: image_array });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, inputSetting, htmlString);
  }, [stateForm, inputSetting, htmlString]);

  // 入力制御 =====================================
  const onChangeText = (field: string) => (newText: any) => {
    setStateForm((prev) => ({ ...prev, [field]: newText }));
  };

  const onChangeDate = (field: string) => (newText: string) => {
    const mess = Validation.validateDate(newText ?? "", "", false);
    setFormError({ ...formError, [field]: mess });
    setStateForm({ ...stateForm, [field]: newText });
  };

  useEffect(() => {
    // マスタが変更された時、追加項目を表示するセルの値を変更する
    const list_custom_input = inputSetting.filter(
      (v) => v.input >= EXCEL_TEMPLATE_INPUT.MASTER_CUSTOM_ITEM,
    );
    var newStateForm = cloneDeep(stateForm);
    list_custom_input.forEach((v) => {
      if (v.master_coordinate && v.master_attr) {
        if (stateForm[v.master_coordinate]) {
          newStateForm = {
            ...newStateForm,
            [v.coordinate]: stateForm[v.master_coordinate][v.master_attr],
          };
        }
      }
    });
    if (!isEqual(newStateForm, stateForm)) {
      setStateForm(newStateForm);
    }
  }, [stateForm, inputSetting]);

  const listSignature = useMemo(() => {
    return inputSetting
      .filter((item) => item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE)
      .map((item) => item.coordinate);
  }, [inputSetting]);

  const getNextSignatureRef = (coordinate: string) => {
    const list_index = listSignature.indexOf(coordinate);
    if (list_index < listSignature.length - 1 && signatureRef.current) {
      return signatureRef.current[listSignature[list_index + 1]];
    }
    return undefined;
  };

  const getPrevSignatureRef = (coordinate: string) => {
    const list_index = listSignature.indexOf(coordinate);
    if (list_index > 0 && signatureRef.current) {
      return signatureRef.current[listSignature[list_index - 1]];
    }
    return undefined;
  };

  const onValidateNumber = (setting: TemplateExcelDataInfo) => {
    const field = setting.coordinate;
    const value = stateForm[field];
    const mess = Validation.validate({
      type: "number",
      name: setting.name,
      value: value,
    });
    setFormError({ ...formError, [field]: mess });
  };

  // 保存 =====================================
  const onSubmit = async () => {
    let newStateForm = { ...stateForm };
    if (!isSetting) {
      // 画像アップロード
      let newImageArray: Array<Type.ImageInfoType> =
        stateForm.image_array ?? [];
      if (stateForm.image_array) {
        newImageArray = await uploadImageToS3(stateForm.image_array);
      }
      newStateForm = {
        ...newStateForm,
        image_array: newImageArray,
      };
      // 添付画像ページアップロード
      let custom_image: Array<Type.ImageInfoType> = [];
      const image_input = inputSetting.filter(
        (item) =>
          item.input === EXCEL_TEMPLATE_INPUT.IMAGE ||
          item.input === EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
      );
      for (let index = 0; index < image_input.length; index++) {
        const item = image_input[index];
        const new_input_image = await uploadImageToS3(
          [stateForm[item.coordinate]],
          Date.now(),
        );
        newStateForm = {
          ...newStateForm,
          [item.coordinate]: new_input_image[0],
        };
        if (new_input_image[0] && new_input_image[0]["path_file"] != "") {
          custom_image.push(new_input_image[0]);
        }
      }
      newStateForm = { ...newStateForm, custom_image: custom_image };
      // サインアップロード
      const signature_input = inputSetting.filter(
        (item) => item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE,
      );
      for (let index = 0; index < signature_input.length; index++) {
        const item = signature_input[index];
        const new_signature_image = await uploadImageToS3(
          [stateForm[item.coordinate]],
          Date.now(),
        );
        newStateForm = {
          ...newStateForm,
          [item.coordinate]: new_signature_image[0],
        };
      }
    }
    await setStateForm(newStateForm);
  };

  // 画面表示 =====================================
  const _render = (item: TemplateExcelDataInfo, index: number) => {
    if (item.input == EXCEL_TEMPLATE_INPUT.TEXT) {
      return (
        <TextInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.MASTER) {
      return (
        <MasterInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          reportItem={listReportItem}
          listMasterType={listMasterType}
          activityBaseId={activityBaseId ?? location_id}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.DATE) {
      return (
        <DateInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeDate(item.coordinate)}
          error={formError[item.coordinate]?.length > 0}
          helperText={formError[item.coordinate]}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.IMAGE) {
      return (
        <ImageInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.IMAGE_PAGE) {
      return (
        <ImagePageField
          title={item.titleImage}
          imagesList={stateForm?.image_array || []}
          addButtonText={"写真を追加"}
          onChange={onChangeText("image_array")}
          maxImage={6}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.USER_NAME) {
      return (
        <AccountInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={(value) => {
            onChangeText(item.coordinate)(value);
            // 当該アカウントの電子印があれば電子印も変更
            const seal = inputSetting.filter(
              (v) =>
                v.input === EXCEL_TEMPLATE_INPUT.USER_SEAL &&
                v.default === item.coordinate,
            );
            if (seal) {
              seal.forEach((v) => {
                onChangeText(v.coordinate)(
                  value.is_manual_input ? value.name : value.id,
                );
              });
            }
          }}
          userList={listUser}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.SIGNATURE) {
      return (
        <SignatureInputField
          setRef={(v) => {
            if (v) {
              signatureRef.current = {
                ...signatureRef.current,
                [item.coordinate]: v,
              };
            }
          }}
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          disabledNext={
            listSignature.indexOf(item.coordinate) === listSignature.length - 1
          }
          nextRef={getNextSignatureRef(item.coordinate)}
          disabledPrev={listSignature.indexOf(item.coordinate) !== 0}
          prevRef={getPrevSignatureRef(item.coordinate)}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.DRAW_IMAGE) {
      return (
        <DrawImageInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          key={index}
        />
      );
    } else if (item.input === EXCEL_TEMPLATE_INPUT.NUMBER) {
      return (
        <NumberInputField
          setting={item}
          value={stateForm[item.coordinate]}
          onChange={onChangeText(item.coordinate)}
          onBlur={() => {
            onValidateNumber(item);
          }}
          error={formError[item.coordinate]?.length > 0}
          helperText={formError[item.coordinate]}
          key={index}
        />
      );
    }
  };

  return inputSetting.length > 0 && stateForm ? (
    <Box>
      <Stack>
        <Card>
          <CardContent>
            <Stack>
              {inputSetting.map((item, index) => {
                if (item.categoryTitle) {
                  if (
                    index == 0 ||
                    item.categoryTitle != inputSetting[index - 1].categoryTitle
                  ) {
                    const input_setting = inputSetting.filter(
                      (f) =>
                        f.categoryTitle == item.categoryTitle &&
                        !list_input_not_disp.includes(f.input as any),
                    );
                    if (input_setting.length > 0) {
                      return (
                        <AccordionSection
                          title={item.categoryTitle}
                          key={index}
                        >
                          <Stack>
                            {input_setting.map((item1, index1) =>
                              _render(item1, index1),
                            )}
                          </Stack>
                        </AccordionSection>
                      );
                    }
                  }
                  return null;
                } else {
                  return _render(item, index);
                }
              })}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

export default forwardRef(TemplateExcelReport);
