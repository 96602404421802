import React, { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import { useSize } from "shared/hook/useSize";
import { Colors } from "@template/style";
import {
  createFormUpload,
  getBarcodeLayoutByTemplateData,
  getFileByUrl,
  getUserId,
  getUserInfo,
} from "@utils/index";
import {
  Template,
  TemplateExcel,
  TemplateExcelDataInfo,
  User,
} from "services/models";
import messages from "config/messages";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import LabelRequired from "components/atoms/LabelRequired";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { useDispatch, useSelector } from "react-redux";
import { TYPES } from "store/types";
import {
  listLocationProject,
  listStatusProject,
} from "selector/managerMasterSelector";
import _ from "lodash";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import {
  getDetailProjectTlog,
  updateIsDeletedProject,
  updateProjectApi,
} from "@api/project";
import { Validation } from "@validation";
import formatDateToString from "@utils/DateFormat";
import { RootState } from "store/reducer";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { STORAGE, storeData } from "@utils/Storage";
import LabelValueText from "components/atoms/LabelValueText";
import { useLocation } from "react-router-dom";
import ProjectGroupInfoOption, {
  IGroupEditData,
} from "components/organisms/ProjectGroupInfoOption";
import TemplateCreateBox from "screens/CreateProjectTlog/TemplateCreateBox/TemplateCreateBox";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { updateExcelTemplateApi } from "@api/template";
import ModalCopyCustomTemplateConfirm from "components/molecules/ModalCopyCustomTemplateConfirm";
import { getBarcodeLayoutDetail, updateBarcodeLayout } from "@api/barcode";

type IStateForm = {
  project_id: string;
  project_name: string;
  report_id: string;
  project_location_id: string;
  project_start_date: string;
  project_end_date: string;
  template: null | Template;
  memo: string;
  report_type_code: string;
  report_name: string;
  report_number: string;
  is_daily: boolean;
  is_user_made: boolean;
  report_type_id: string;
  is_corp_standard: boolean;
  open_state: boolean;
  group_info: IGroupEditData[];
  print_2d_barcode: boolean;
};

type IFormError = {
  project_name: string;
  template: string;
  project_start_date: string;
  project_end_date: string;
  memo: string;
};

const EditProjectTlogScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const [flowUser, setFlowUser] = useState<User[]>([]);
  const listStatus = useSelector(listStatusProject);
  const listLocation = useSelector(listLocationProject);
  const projectId = useSelector(
    (state: RootState) => state.projectInfo.selected_project_id,
  );
  const [selectedInit, setSelectedInit] = useState<User[]>([]);
  const [stateForm, setStateForm] = useState<IStateForm>({
    project_id: "",
    project_name: "",
    report_id: "",
    project_location_id: "",
    project_start_date: "",
    project_end_date: "",
    template: null,
    memo: "",
    report_type_code: "",
    report_name: "",
    report_number: "",
    report_type_id: "",
    is_daily: false,
    is_user_made: false,
    is_corp_standard: false,
    open_state: true,
    group_info: [],
    print_2d_barcode: false,
  });
  const [formError, setFormError] = useState<IFormError>({
    project_name: "",
    template: "",
    project_start_date: "",
    project_end_date: "",
    memo: "",
  });
  const [managerId, setManagerId] = useState<null | string>(null);
  const [flowApproveUser, setFlowApproveUser] = useState<Array<any>>([]);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { isSmUp } = useSize();
  const location = useLocation<any>();
  const typeAction = location.state?.typeAction;
  const from_user_made = typeAction === "custom";
  const groupInfoRef = React.useRef<any>();
  const [templateExcel, setTemplateExcel] = useState<TemplateExcel>();
  const [isErrorTemplate, setIsErrorTemplate] = useState<boolean>(false);
  const [openCopyMaster, setOpenCopyMaster] = useState<boolean>(false);
  const [copyData, setCopyData] = useState<any>();
  const TemplateCreateRef = React.useRef<{
    stateForm: any;
    formError: { [key: string]: string };
    validationAllCheck: (update?: boolean) => boolean;
    hasUpdateData: () => boolean;
  }>();
  const isFetchDataRef = React.useRef<boolean>(true);
  const history = useHistoryCustom();
  const user_info = getUserInfo();

  const [originData, setOriginData] = useState<IStateForm>({
    project_id: "",
    project_name: "",
    report_id: "",
    project_location_id: "",
    project_start_date: "",
    project_end_date: "",
    template: null,
    memo: "",
    report_type_code: "",
    report_name: "",
    report_number: "",
    is_daily: false,
    is_user_made: false,
    report_type_id: "",
    is_corp_standard: false,
    open_state: true,
    group_info: [],
    print_2d_barcode: false,
  });

  const hasUpdateData = () => {
    return (
      (stateForm && originData && !_.isEqual(stateForm, originData)) ||
      (selectedInit &&
        selectedUser &&
        !_.isEqual(selectedUser, selectedInit)) ||
      (TemplateCreateRef.current
        ? TemplateCreateRef.current.hasUpdateData()
        : false)
    );
  };

  useEffect(() => {
    setIsEdited(hasUpdateData());
  }, [stateForm, selectedUser, originData]);

  // データ取得 ------------------------------------------------
  const fetchDetailProject = async () => {
    try {
      LoadingOverlayController.show();
      const res = await getDetailProjectTlog(projectId);
      if (res?.data) {
        const dataRes = res?.data;
        setManagerId(`USER#${dataRes.created_by}`);
        const updateStateForm = {
          ...stateForm,
          project_id: projectId,
          project_name: dataRes?.project_name,
          report_id: dataRes?.report_id,
          project_location_id: dataRes?.project_location_id,
          project_start_date: dataRes?.project_start_date,
          project_end_date: dataRes?.project_end_date,
          template: dataRes?.template_project_id ?? null,
          memo: dataRes?.memo,
          report_type_code: dataRes?.report_type_code,
          report_name: dataRes?.report_name,
          report_number: dataRes?.report_number,
          is_daily: dataRes?.is_daily,
          report_type_id: dataRes?.report_type_id,
          is_corp_standard: dataRes?.is_corp_standard,
          open_state: dataRes?.open_state,
          is_user_made: dataRes?.is_user_made,
          print_2d_barcode: dataRes?.print_2d_barcode,
        };
        // setFlowUser(JSON.parse(dataRes?.list_approver ?? "[]"));
        // setFlowApproveUser(JSON.parse(dataRes?.flow_approve_user ?? "[]"));
        // if (dataRes.users.length > 1) {
        //   const indexManager = dataRes.users?.findIndex((item: User) =>
        //     item.SK.includes(dataRes.created_by),
        //   );
        //   if (indexManager > -1) {
        //     const findUser = dataRes.users[indexManager];
        //     const listUser = dataRes?.users?.slice();
        //     listUser.splice(indexManager, 1);
        //     const formatSelectUser = [findUser, ...listUser];
        //     setSelectedUser(formatSelectUser);
        //     setSelectedInit(formatSelectUser);
        //   }
        // } else {
        //   setSelectedUser(dataRes.users);
        //   setSelectedInit(dataRes.users);
        // }
        setOriginData(updateStateForm);
        setStateForm(updateStateForm);
        setTemplateExcel({
          PK: dataRes?.template_project_id?.PK,
          SK: dataRes?.template_project_id?.SK,
          excel_path: dataRes?.template_project_id?.excel_path,
          excel_name: dataRes?.template_project_id?.excel_name,
          start_col: dataRes?.template_project_id?.start_col,
          start_row: dataRes?.template_project_id?.start_row,
          end_col: dataRes?.template_project_id?.end_col,
          end_row: dataRes?.template_project_id?.end_row,
          sheet_name: dataRes?.template_project_id?.sheet_name,
          font: dataRes?.template_project_id?.font,
          paper_size: dataRes?.template_project_id?.paper_size,
          horizontal_pixel_size:
            dataRes?.template_project_id?.horizontal_pixel_size,
          vertical_pixel_size:
            dataRes?.template_project_id?.vertical_pixel_size,
          is_landscape: dataRes?.template_project_id?.is_landscape,
          data_info: dataRes?.template_project_id?.data_info,
          excel: {
            path: dataRes?.template_project_id?.excel_name,
            uri: dataRes?.template_project_id?.excel_path_s3,
            non_Local: true,
          },
        });
      }

      setTimeout(async () => {
        await fetchGroupInfo();
      }, 500);
    } catch (error: any) {
      console.log("error getDetailProject", error);
      ModalController.show({
        message: error?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    dispatch({ type: TYPES.GET_LIST_STATUS_PROJECT });
    dispatch({ type: TYPES.GET_LIST_LOCATION });

    fetchDetailProject();
  }, []);

  const fetchGroupInfo = async () => {
    if (groupInfoRef && groupInfoRef.current) {
      await groupInfoRef.current.handlefetchList();
    }
  };

  // ボタン制御 ------------------------------------------------
  const isActiveButton = () => {
    if (hasUpdateData()) {
      const error =
        Object.values(formError).filter((item: string) => item !== "").length >
        0;
      if (
        stateForm.project_name.trim() !== "" &&
        stateForm.project_location_id !== "" &&
        (!stateForm.is_user_made ||
          (stateForm.is_user_made && !isErrorTemplate)) &&
        !isDisable &&
        !error
      ) {
        return true;
      }
    }
    return false;
  };

  const usersDefault = useMemo(() => {
    const result: User[] = [...selectedInit];
    return result.filter((user1) => {
      return (
        getUserId(user1) === managerId ||
        flowUser.findIndex((user2) => getUserId(user1) === getUserId(user2)) !==
          -1
      );
    });
  }, [managerId, flowUser, selectedInit]);

  const usersSelectedFilter = useMemo(() => {
    const result: User[] = [];
    selectedUser.map((user2) => {
      if (
        usersDefault.findIndex((user1) => {
          return getUserId(user1) === getUserId(user2);
        }) === -1
      ) {
        result.push(user2);
      }
    });
    return result;
  }, [selectedUser, usersDefault]);

  // 拠点入力 ------------------------------------------------
  const onChangeLevel1 = (item: any, group_info: IGroupEditData[]) => {
    if (isFetchDataRef.current) {
      setOriginData((pre) => {
        return {
          ...pre,
          group_info: group_info ?? [],
        };
      });
      isFetchDataRef.current = false;
    }
  };

  // 入力制御 ------------------------------------------------
  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    let newStateForm = { ...stateForm, [field]: newText };

    setIsEdited(!_.isEqual(newStateForm, originData));
    setStateForm(newStateForm);
  };

  const handleChangeDate = (
    newValue: string | null,
    field: keyof IStateForm,
  ) => {
    if (!newValue) {
      newValue = "";
    } else {
      newValue = formatDateToString(newValue, "YMD");
    }
    let mess = "";
    let newStateForm = { ...stateForm, [field]: newValue };
    if (newValue) {
      mess = validator(field, newValue);
    }
    setFormError({ ...formError, [field]: mess });
    if (mess.length === 0) {
      checkProjrctDate(
        newStateForm.project_start_date,
        newStateForm.project_end_date,
      );
    }

    setIsEdited(!_.isEqual(newStateForm, originData));
    setStateForm(newStateForm);
  };

  const onValidateText = (field: keyof IStateForm) => {
    if (field === "template") return;
    const mess = validator(field, String(stateForm[field]));
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IStateForm, value: string) => {
    let mess: string = "";

    switch (field) {
      case "project_name":
        mess = Validation.validate({
          type: "text",
          value: value,
          name: "帳票名",
          required: true,
          max_length: 50,
        });
        break;
      case "project_start_date":
        mess = Validation.validateDate(value, "期間開始", false);
        break;
      case "project_end_date":
        mess = Validation.validateDate(value, "期間終了", false);
        break;
      case "memo":
        mess = Validation.validate({
          type: "text",
          name: "備考",
          value: value,
          required: false,
          max_length: 200,
        });
        break;
      case "is_daily":
        break;
    }
    return mess;
  };

  const checkProjrctDate = (start: string, end: string) => {
    let isError = false;
    let message_start = "";
    let message_end = "";

    if (!start && end) {
      isError = true;
      message_start = messages.PROJECT.MSG_ONLY_END_DATE;
    }

    if (start && end) {
      message_start = Validation.validateDateRange(
        new Date(start),
        new Date(end),
      );
      if (message_start) {
        message_end = message_start;
        isError = true;
      }
    }
    setFormError((prev) => {
      return {
        ...prev,
        project_start_date: message_start,
        project_end_date: message_end,
      };
    });
    return isError;
  };

  const validationAllCheck = () => {
    let response = true;

    const mess_project_name = validator("project_name", stateForm.project_name);
    const mess_project_start_date = validator(
      "project_start_date",
      stateForm.project_start_date,
    );
    const mess_project_end_date = validator(
      "project_end_date",
      stateForm.project_end_date,
    );
    const mess_memo = validator("memo", stateForm.memo);

    if (
      mess_project_name.length > 0 ||
      mess_project_start_date.length > 0 ||
      mess_project_end_date.length > 0 ||
      mess_memo.length > 0
    )
      response = false;

    setFormError({
      ...formError,
      project_name: mess_project_name,
      project_start_date: mess_project_start_date,
      project_end_date: mess_project_end_date,
      memo: mess_memo,
    });

    if (
      mess_project_start_date.length === 0 &&
      mess_project_end_date.length === 0
    ) {
      if (
        checkProjrctDate(
          stateForm.project_start_date,
          stateForm.project_end_date,
        )
      )
        response = false;
    }

    return response;
  };

  // 保存 ------------------------------------------------
  const handleUpdateProject = async () => {
    try {
      if (!validationAllCheck()) {
        return false;
      }

      LoadingOverlayController.show();

      if (stateForm.is_user_made) {
        var res_update_excel_template = await updateExcelTemplate();
        if (!res_update_excel_template) {
          return false;
        }
      }

      // setIsDisable(true);
      // let arrUserUpdate: any = [];
      // for (const userItem of selectedUser) {
      //   let isAdd = false;
      //   selectedInit.forEach((element) => {
      //     if (userItem.SK === element.SK) {
      //       isAdd = true;
      //     }
      //   });
      //   if (isAdd === false) {
      //     arrUserUpdate.push({ user_id: userItem.SK, is_delete: isAdd });
      //   }
      // }

      // for (const userItem of selectedInit) {
      //   let isDel = true;
      //   for (const userISelect of selectedUser) {
      //     if (userItem.SK === userISelect.SK) {
      //       isDel = false;
      //     }
      //   }
      //   if (isDel === true) {
      //     arrUserUpdate.push({
      //       user_id: userItem.SK,
      //       is_delete: true,
      //     });
      //   }
      // }
      const data = {
        project_name: stateForm.project_name,
        project_location_id: stateForm.project_location_id,
        project_start_date: stateForm.project_start_date,
        project_end_date: stateForm.project_end_date,
        memo: stateForm.memo,
        is_corp_standard: stateForm.is_corp_standard,
        open_state: stateForm.open_state,
        open_location_list: JSON.stringify(
          stateForm.group_info.map((item) => item.SK),
        ),
        group_info: JSON.stringify(
          stateForm.group_info.map((item) => ({
            SK_group: item.SK,
            group_parentid: item.group_parentid,
          })),
        ),
        print_2d_barcode: stateForm.print_2d_barcode,
      };
      const formData = createFormUpload(null, data);
      const res = await updateProjectApi(projectId, formData);
      if (res?.data) {
        // const res_p = await getDetailProject(res?.data);
        // dispatch({
        //   type: TYPES.SET_SELECTED_PROJECT_ID,
        //   selected_project_id: res_p?.data.SK,
        //   selected_project_name: res_p?.data.project_name,
        // });
        if (stateForm.is_user_made) {
          // 代表項目を更新
          await updateBarcodeLayoutData();
        }
        ModalController.show({
          message: messages.PROJECT.MSG_UPDATE_SUCCESS(stateForm.project_name),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchDetailProject();
          },
        });
        return true;
      }
      return false;
    } catch (error: any) {
      console.log("error handleUpdateProject", error);
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
      return false;
    } finally {
      LoadingOverlayController.hide();
      setIsDisable(false);
    }
  };

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({ onOK: handleUpdateProject });

  const updateExcelTemplate = async () => {
    try {
      if (!TemplateCreateRef.current) return false;
      const excelform = TemplateCreateRef.current.stateForm;
      const data = {
        PK: excelform.PK,
        SK: excelform.SK,
        excel_name: excelform.excel.path,
        start_col: excelform.start_col,
        start_row: excelform.start_row,
        end_col: excelform.end_col,
        end_row: excelform.end_row,
        sheet_name: excelform.sheet_name,
        font: excelform.font,
        paper_size: excelform.paper_size,
        vertical_pixel_size: excelform.vertical_pixel_size,
        horizontal_pixel_size: excelform.horizontal_pixel_size,
        is_landscape: excelform.is_landscape,
        data_info: excelform.data_info,
        template_name: stateForm.project_name,
      };
      let excel = excelform.excel;
      if (excel.non_Local) {
        excel = undefined;
      }
      const formData = createFormUpload(excel, data);
      const res = await updateExcelTemplateApi(formData);
      if (res?.data) {
        return res.data;
      }
      return false;
    } catch (error: any) {
      console.log("error updateExcelTemplate", error);
      ModalController.show({
        message: error?.detail?.message,
        visibleButton2: true,
      });
      return false;
    }
  };

  const updateBarcodeLayoutData = async () => {
    if (!TemplateCreateRef.current) return;

    // バーコードレイアウトデータを取得
    const res = await getBarcodeLayoutDetail(
      stateForm.project_location_id,
      projectId,
    );
    if (res?.data) {
      const barcode_layout = JSON.parse(res.data.display_items);

      // テンプレートの入力設定を取得
      const excelform = TemplateCreateRef.current.stateForm;
      const template_data_info: Array<TemplateExcelDataInfo> =
        excelform.data_info;

      // テンプレート入力設定からバーコードレイアウトデータを作成
      var new_layout = { ...barcode_layout };
      Object.keys(new_layout).forEach((field) => {
        var coordinate = new_layout[field].value_key[0];
        const data_info = template_data_info.find(
          (item) => item?.coordinate === coordinate,
        );
        if (data_info) {
          var new_value = getBarcodeLayoutByTemplateData(data_info);
          if (new_value) {
            new_layout = { ...new_layout, [field]: new_value };
          }
        }
      });

    // 更新
    const data = {
      SK: res.data.SK,
      display_items: JSON.stringify(new_layout),
    };
    await updateBarcodeLayout(data);

    // storageを更新
    dispatch({
      type: TYPES.SET_2D_BARCODE_LAYOUT,
      payload: { location_id: user_info.location_id },
    });
    }
  };

  // 削除 ------------------------------------------------
  const handleDeleteProject = async () => {
    try {
      LoadingOverlayController.show();
      const params = { is_deleted: true };
      const res = await updateIsDeletedProject(projectId, params);
      if (res?.data) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(
            `案件「${stateForm.project_name}」`,
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            setIsEdited(false);
            window.history.back();
          },
        });
      }
    } catch (error: any) {
      console.log("error handleDeleteProject", error);
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 複製 ------------------------------------------------
  const handleClickCopy = () => {
    const data: any = {
      SK: projectId,
      template_project_id: templateExcel?.SK,
      project_location_id: stateForm.project_location_id,
    };
    setCopyData(data);
    setOpenCopyMaster(true);
  };

  // 二次元バーコードレイアウト
  const handleBarcodeLayout = () => {
    history.pushWithRef("/barcode-layout", {
      project_id: stateForm.project_id,
    });
  };

  return (
    <GenericTemplate title="帳票情報">
      <Stack>
        <Card>
          <CardHeader
            title="基本情報"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              <Stack sx={{ pb: 0 }}>
                <TextField
                  label={<LabelRequired title="帳票名" />}
                  value={stateForm.project_name}
                  inputProps={{
                    maxLength: 50,
                  }}
                  onChange={(e) => onChangeText("project_name")(e.target.value)}
                  onBlur={() => onValidateText("project_name")}
                  error={formError.project_name.length > 0}
                  helperText={formError.project_name}
                />
                <LabelValueText
                  label="帳票"
                  value={`${stateForm.report_type_code}${stateForm.report_number}:${stateForm.report_name}`}
                />
                <ProjectGroupInfoOption
                  ref={groupInfoRef}
                  stateForm={stateForm}
                  setStateForm={setStateForm}
                  allowEditTopLocation={false}
                  allowEditGroup={stateForm.is_user_made ?? false}
                  handleChangeLevel1={onChangeLevel1}
                />
                <LabelValueText label="帳票部類" value="日次帳票" />
                <FormControl>
                  <InputLabel>帳票様式</InputLabel>
                  <Box>
                    <CheckboxLabel
                      label={"全社標準"}
                      checked={stateForm.is_corp_standard}
                      disabled={!stateForm.is_user_made}
                      onChange={(_, checked) => {
                        setStateForm((prev) => ({
                          ...prev,
                          is_corp_standard: checked,
                        }));
                      }}
                    />
                  </Box>
                </FormControl>

                <FormControl>
                  <InputLabel>
                    <LabelRequired title="公開状態" />
                  </InputLabel>
                  <RadioGroup
                    name="open-radio-group"
                    value={stateForm.open_state ?? true}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        open_state: value === "true" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      label="公開"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={false}
                      label="非公開"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <InputLabel>公開期間</InputLabel>
                  <Stack direction={isSmUp ? "row" : "column"}>
                    <DatePickerCustom
                      value={stateForm.project_start_date}
                      onChange={(v) =>
                        handleChangeDate(v, "project_start_date")
                      }
                      label="開始"
                      helperText={formError.project_start_date}
                      error={formError.project_start_date.length > 0}
                    />
                    {isSmUp && (
                      <Typography sx={{ alignSelf: "center", pt: 2 }}>
                        ～
                      </Typography>
                    )}
                    <DatePickerCustom
                      value={stateForm.project_end_date}
                      onChange={(v) => handleChangeDate(v, "project_end_date")}
                      label="終了"
                      helperText={formError.project_end_date}
                      error={formError.project_end_date.length > 0}
                    />
                  </Stack>
                </FormControl>

                <FormControl>
                  <InputLabel>
                    <LabelRequired title="２次元バーコード利用" />
                  </InputLabel>
                  <RadioGroup
                    name="print-radio-group"
                    value={stateForm.print_2d_barcode ?? false}
                    row
                    onChange={(_, value) => {
                      setStateForm({
                        ...stateForm,
                        print_2d_barcode: value === "true" ? true : false,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={false}
                      label="なし"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={true}
                      label="あり"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="テンプレート"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              {!stateForm.is_user_made ? (
                <TemplateSelectBox
                  template={stateForm.template}
                  onClick={() => {
                    // 別タブで開く
                    const data = {
                      type: "url",
                      html: stateForm.template?.template_preview_path,
                    };
                    storeData(STORAGE.PREVIEW_DATA, JSON.stringify(data));
                    window.open("/preview");
                  }}
                  disableEdit={true}
                />
              ) : (
                <TemplateCreateBox
                  ref={TemplateCreateRef}
                  template={templateExcel}
                  onChange={() => {
                    setIsEdited(hasUpdateData());
                  }}
                  onError={(value: boolean) => {
                    setIsErrorTemplate(value);
                  }}
                  activityBaseId={stateForm.project_location_id}
                />
              )}
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="備考" sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }} />
          <CardContent>
            <FormGroup>
              <TextField
                label="備考"
                name="memo"
                multiline
                rows={3}
                value={stateForm.memo}
                inputProps={{
                  maxLength: 200,
                }}
                onChange={(e) => onChangeText("memo")(e.target.value)}
                onBlur={() => onValidateText("memo")}
                placeholder="メモや備考を記入できます（最大200文字）"
                error={formError.memo.length > 0}
                helperText={formError.memo}
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
        {from_user_made && (
          <>
            <Button color="error" onClick={handleDeleteProject}>
              削除
            </Button>
            <Button onClick={handleClickCopy}>複製</Button>
            <Button onClick={handleBarcodeLayout}>代表項目設定</Button>
          </>
        )}
        <Button
          onClick={handleUpdateProject}
          disabled={!isActiveButton()}
          color="secondary"
        >
          {"保存"}
        </Button>
      </Box>
      <ModalCopyCustomTemplateConfirm
        open={openCopyMaster}
        user_info={user_info}
        data={copyData}
      />
    </GenericTemplate>
  );
};
export default EditProjectTlogScreen;
